import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react';
import firebase, { auth } from '../firebase';

export const AppContext = createContext()

const AppContextProvider = ({ children }) => {


    const [admin, setAdmin] = useState(null)
    const [user, setUser] = useState(null)
    const [staff, setStaff] = useState(null)


    const getUserDetails = async () => {

        try {
            firebase
                .firestore()
                .collection("User")
                .where("email", "==", user.email)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        getAdminDetails()
                    } else {
                        querySnapshot.forEach((doc) => {
                            // console.log(doc.data())
                            setUser({ id: doc.id, ...doc.data() })

                        });
                    }

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);

                })

        } catch (error) {
            console.log(error);
            alert('Error, Please try again')
        }
    }

    const getAdminDetails = async () => {

        try {
            firebase
                .firestore()
                .collection("DashboardAdmins")
                .where("email", "==", user.email)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        // FalseAdminHandler()  // used to handle when wrong user logged in
                    } else {
                        querySnapshot.forEach((doc) => {
                            // console.log(doc.data())
                            setAdmin({ id: doc.id, ...doc.data() })

                        });
                    }

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);

                })

        } catch (error) {
            console.log(error);
            alert('Error, Please try again')
        }
    }

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            setUser(user)
        })
        return () => unsubscribe()
     
    }, [])

    useEffect(() => {
        if (user) {
            getUserDetails()
        }
    }, [user])

    const values = {
        admin,
        setAdmin,
        staff,
        setStaff,
        getUserDetails

    }

    return (
        <AppContext.Provider value={values} >
            {children}
        </AppContext.Provider>
    )
}

export default AppContextProvider