import React, { useEffect, useState } from 'react'
import firebase from "../../../firebase";

import PhonePe from '../../../assets/images/PhonePe.png'
import GPay from '../../../assets/images/gpay.png'
import Paytm from '../../../assets/images/Paytm.png'
import BharatPay from '../../../assets/images/bharatPay.png'
import AmazonPay from '../../../assets/images/amznPay.png'
import Instagram from "../../../assets/images/instagram.png";
import Whatsapp from "../../../assets/images/whatsapp.png";
import Facebook from "../../../assets/images/facebook.png";
import youtube from "../../../assets/images/youtube.png";
import snapchat from "../../../assets/images/snapchat.png";
import twitter from "../../../assets/images/twitter.png";
import linkedIN from "../../../assets/images/linkedIN.png";
import clubhouse from "../../../assets/images/clubHouse.png";
import call from "../../../assets/images/call.png";
import contact from "../../../assets/images/contacts.png";
import textMessage from "../../../assets/images/textMessage.png";
import emailIcon from "../../../assets/images/email.png";
import location from "../../../assets/images/location.png";
import customURL from "../../../assets/images/URL.png";
import Roposo from "../../../assets/images/Roposo.png";
import Takatak from "../../../assets/images/MXTakatak.png";
import Moj from "../../../assets/images/moj.png";
import Quora from "../../../assets/images/quora.png";
import Spotify from "../../../assets/images/spotify.png";
import Josho from "../../../assets/images/Josh.png";
import Pinterest from "../../../assets/images/pinterest.png";
import Discord from "../../../assets/images/discord.png";
import Telegram from "../../../assets/images/telegram.png";
import Btn from '../../../components/btn/Btn'
import CrossIco from '../../../assets/images/cross.png'
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';

import PhoneCode from 'react-phone-code';

const EditSocialMediaModal = ({ media: data, user, setShowMediaModal, allUpdate, staffs }) => {

    const { admin, staff } = useContext(AppContext)

    console.log(admin, '=========', staff, 'staff')
    const [icon, setIcon] = useState();
    const [media, setMedia] = useState(data)
    const [placeHolder, setPlaceHolder] = useState(`Enter ${media?.title} Profile Link`);



    const saveHandler = async () => {


        if (media.title == 'Instagram' || media.title == 'SnapChat' || media.title == 'Twitter') {
            if (media.link.includes('http')) {
                return alert('Only userId allowed, You cannot add whole link here.')
            }
        }
        if (media.title == 'Youtube') {
            if (!media.link.includes('http')) {
                return alert('Only Link allowed, You need to  add whole link here.')
            }
        }

        if (media.title == 'Email') {
            const expression =
                /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

            if (!expression.test(String(media.link).toLowerCase())) return alert('Only Link allowed You need to  add whole link here.')
        }

        // if (media.title == 'Whatsapp' || media.title == 'Telegram' || media.title == 'Call' || media.title == 'Text') {
        //     if (media.link.length < 10) {
        //         return alert('Enter complete phone number.')
        //     }
        // }


        if (allUpdate) {
            return updateAll()
        }

        let tmpMedia = user.socialMedia


        let alreadyPresent = false
        tmpMedia.forEach((s, id) => {
            if (s.title == media.title) {
                alreadyPresent = true
                tmpMedia[id] = media
            }
        })

        if (!alreadyPresent) {
            tmpMedia.push(media)
        }

        try {
            await firebase
                .firestore()
                .collection("Users")
                .doc(admin ? staff?.id : user?.id)
                .update({
                    socialMedia: tmpMedia
                }).then(() => {
                    setShowMediaModal(null)
                })

        } catch (error) {
            console.log(error);
        }
    }

    const deleteHandler = async () => {


        let tmpMedia = user.socialMedia

        tmpMedia = tmpMedia.filter(it => it.title != media.title)

        try {
            await firebase
                .firestore()
                .collection("Users")
                .doc(admin ? staff?.id : user?.id)
                .update({
                    socialMedia: tmpMedia
                }).then(() => {
                    setShowMediaModal(null)
                })

        } catch (error) {
            console.log(error);
            alert('Couldnt delete')
        }
    }

    const updateAll = async () => {


        let socialMedia = []
        let alreadyPresent = false
        await firebase.firestore().collection("CommonDatas")
            .doc('data')
            .get().then((doc) => {
                if (doc.data()) {
                    socialMedia = doc.data().socialMedia
                }
            })

        socialMedia.forEach((s, id) => {
            if (s.title == media.title) {
                alreadyPresent = true
                socialMedia[id] = media
            }
        })
        if (!alreadyPresent) {
            socialMedia.push(media)
        }
        await firebase.firestore().collection("CommonDatas")
            .doc('data')
            .update({
                socialMedia: socialMedia
            })
        // .then(() => {
        //     setShowMediaModal(null)
        //     alert('Updated...')
        // })


        let promises = staffs.map(async (staff) => {
            let alreadyPresent = false
            let socialMedia = staff.socialMedia

            socialMedia.forEach((s, id) => {
                if (s.title == media.title) {
                    alreadyPresent = true
                    socialMedia[id] = media
                }
            })
            if (!alreadyPresent) {
                socialMedia.push(media)
            }

            return await firebase.firestore().collection("Users")
                .doc(staff.id)
                .update({
                    socialMedia: socialMedia
                })
            // .then(() => {
            //     setShowMediaModal(null)
            //     alert('Updated...')
            // })
        }
        )

        await Promise.all(promises).then(() => {
            setShowMediaModal(null)
            alert('Updated...')
        })
    }
    useEffect(() => {
        switch (media?.title) {
            case "Instagram":
                setIcon(Instagram);
                break;
            case "Facebook":
                setIcon(Facebook);
                break;
            case "Whatsapp":
                setIcon(Whatsapp);
                break;
            case "Discord":
                setIcon(Discord);
                break;
            case "Telegram":
                setIcon(Telegram);
                setPlaceHolder('Enter your Telegram number')
                break;
            case "Youtube":
                setIcon(youtube);
                setPlaceHolder('Add link to youtube channel')
                break;
            case "SnapChat":
                setIcon(snapchat);
                setPlaceHolder('Enter SnapChat ID')
                break;
            case "Twitter":
                setIcon(twitter);
                break;
            case "Linkedin":
                setIcon(linkedIN);
                break;
            case "ClubHouse":
                setIcon(clubhouse);
                break;
            case "Call":
                setIcon(call);
                setPlaceHolder('Enter your Phone number')
                break;
            case "Contact":
                setIcon(contact);
                setPlaceHolder(admin ? 'Enter first name' : 'Enter name')
                break;
            case "Text":
                setIcon(textMessage);
                setPlaceHolder('Enter your messaging number')
                break;
            case "Email":
                setIcon(emailIcon);
                setPlaceHolder('Enter your email address')
                break;
            case "Google Maps":
                setIcon(location);
                setPlaceHolder('Enter your location')
                break;
            case "Custom Url":
                setIcon(customURL);
                setPlaceHolder('Enter your Url')
                break;
            case "Pinterest":
                setIcon(Pinterest);
                break;
            case "Spotify":
                setIcon(Spotify);
                break;
            case "Moj":
                setIcon(Moj);
                break;
            case "Quora":
                setIcon(Quora);
                break;
            case "MX TakaTak":
                setIcon(Takatak);
                break;
            case "Roposo":
                setIcon(Roposo);
                break;
            case "Josh":
                setIcon(Josho);
                break;
            case 'PhonePe':
                setIcon(PhonePe);
                break;
            case 'Paytm':
                setIcon(Paytm);
                break;
            case 'GPay':
                setIcon(GPay);
                break;
            case 'BharatPay':
                setIcon(BharatPay);
                break;
            case 'AmazonPay':
                setIcon(AmazonPay);
                break;
            default:
                break;
        }
    }, []);
    return (
        <div style={{
            padding: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 300,
            // height: '80vh'
        }} >
            <img
                src={CrossIco}
                style={{ width: 25, height: 25, alignSelf: 'flex-end', cursor: 'pointer' }}
                onClick={() => setShowMediaModal(undefined)}
            />
            <img src={icon} style={{ width: 100, height: 100, objectFit: 'contain' }} alt="" srcset="" />
            <h3 style={{ color: 'black' }} >{media?.title}</h3>

            <div style={{ display: 'flex',  flexDirection: media.title == 'Contact' ? 'column' : 'row' }} >
                {(data?.title == 'Whatsapp' || data?.title == 'Telegram' || data?.title == 'Call' || data?.title == 'Text')

                    &&
                    // <input
                    //     value={media?.countryCode ?? ''}
                    //     // placeholder={placeHolder}
                    //     style={{ margin: 10, padding: 10, paddingLeft: 10, width: '20%' }}
                    //     onChange={(e) => setMedia({ ...media, countryCode: e.target.value })}
                    // />
                    <PhoneCode
                        onSelect={code => setMedia({ ...media, countryCode: code })} // required
                        // showFirst={['IN']}
                        defaultValue={media?.countryCode ?? 'select'}
                        className={'country_code'}
                    // id='some-id'
                    // name='some-name'
                    // className='some class name'
                    // optionClassName='some option class name'
                    />
                }

                <input
                    value={media?.link ?? ''}
                    placeholder={placeHolder}
                    style={{ margin: 10, padding: 10, paddingLeft: 10, }}
                    onChange={(e) => setMedia({ ...media, link: e.target.value })}
                />
                {(data?.title == 'Contact' && admin) &&

                    <input
                        value={media?.lastName ?? ''}
                        placeholder={'Enter last Name'}
                        style={{ margin: 10, padding: 10, paddingLeft: 10, }}
                        onChange={(e) => setMedia({ ...media, lastName: e.target.value })}
                    />

                }
                {(data?.title == 'Contact' && admin) &&

                    <input
                        value={media?.company ?? ''}
                        placeholder={'Enter company name'}
                        style={{ margin: 10, padding: 10, paddingLeft: 10, }}
                        onChange={(e) => setMedia({ ...media, company: e.target.value })}
                    />

                }
                {(data?.title == 'Contact') &&

                    <input
                        value={media?.contactPhNo ?? ''}
                        placeholder={'Enter Phone Number'}
                        style={{ margin: 10, padding: 10, paddingLeft: 10, }}
                        onChange={(e) => setMedia({ ...media, contactPhNo: e.target.value })}
                    />

                }
                {(data?.title == 'Contact') &&
                    <input
                        value={media?.contactEmail ?? ''}
                        placeholder={'Enter your email'}
                        style={{ margin: 10, padding: 10, paddingLeft: 10, }}
                        onChange={(e) => setMedia({ ...media, contactEmail: e.target.value })}
                    />
                }
                {(data?.title == 'Contact' && admin) &&

                    <input
                        value={media?.url ?? ''}
                        placeholder={'Enter Url'}
                        style={{ margin: 10, padding: 10, paddingLeft: 10, }}
                        onChange={(e) => setMedia({ ...media, url: e.target.value })}
                    />

                } {(data?.title == 'Contact' && admin) &&

                    <input
                        value={media?.jobTitle ?? ''}
                        placeholder={'Enter Job Title'}
                        style={{ margin: 10, padding: 10, paddingLeft: 10, }}
                        onChange={(e) => setMedia({ ...media, jobTitle: e.target.value })}
                    />

                }
            </div>


            <div style={{ display: 'flex' }} >
                <Btn label='Delete' onClick={deleteHandler} containerStyle={{ marginTop: 20, marginRight: 10, backgroundColor: 'red', }} />
                <Btn label='Save' onClick={saveHandler} containerStyle={{ marginTop: 20 }} />
            </div>

        </div >
    )
}

export default EditSocialMediaModal