import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "./custom.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Custom1 from "../../assets/images/web/custom1.png";
import Custom2 from "../../assets/images/web/custom2.png";
import SwiperCore, { Autoplay } from "swiper";
import { useHistory } from "react-router-dom";
SwiperCore.use([Autoplay]);

const Custom = () => {
  const data = [
    {
      img: Custom1,
    },
    {
      img: Custom2,
    },
  ];
  const history = useHistory();
  return (
    <div className="custom"
    >
      <div className="subCustom1">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          speed={4000}
        >
          {data.map((item) => (
            <SwiperSlide>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img className="custom_img" src={item.img} alt="" srcset="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="subCustom2">
        <h1 className="custom_bump">Custom Bump</h1>
        <h1
          className="subCustom2_desc"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "22px",
            lineHeight: "180%",
          }}
        >
          Get your name printed on your Bump!
        </h1>
        <div
          style={{
            width: "150px",
            backgroundColor: "black",
            marginTop: 20,
            borderRadius: 24,
            textAlign: "center",
            cursor: "pointer",
            height: "50px",
          }}
          onClick={() => {
            window.open("https://shop.bumpme.in/products", '_self')
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              paddingTop: "6px",
              fontWeight: 600,
              fontSize: "24px",
              color: "white",
              cursor: "pointer",
            }}
          >
            Buy Now
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Custom;
