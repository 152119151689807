import React from "react";
import Header from "../../components/Home/Header";
import "./faqs.css";
function Faqs() {
  const data = [
    {
      quest: "How does it work?",
      ans: "Tie or attach smart pet tag to your pet's neck belt and use our free app to configure the information you want to share.\n Then anyone who taps a smart pet tag can see your pets profile! \n Smart pet tag doesnot interfere with metal. Smart pet tag works with both ios and android.",
    },
    {
      quest: "What is NFC?",
      ans: "NFC is a short range wireless connectivity technology that makes it easy and convenient for consumers to transfer data and information between their electronic devices NFC tag placed on the back of your phone or either surface that lets you instantly share your social media, location, payment platforms and contact info.",
    },
    {
      quest: "How to check NFC compatibility of a phone?",
      ans: "Most of the recent phones have NFC built in the phone. To check NFC compatibility of your phone, simply got to GSMArena.com enter your phone name and check. You may find this information on your phone box as well.",
    },
    {
      quest: "Is app mandatory to scan or share social with others?",
      ans: "No app is not mandatory. The app will let you to setup your profile and activate your tag. Once you activate your tag, you won't need app to use it.",
    },
    {
      quest: "Is the person who scanning the tag requires app?",
      ans: "Not required it will use your browser to view your profile.",
    },
  ];

  const FaqItem = ({ item }) => {
    return (
      <div style={{ paddingTop: 10 }}>
        <p>{item.quest}</p>
        <p>{item.ans}</p>
      </div>
    );
  };

  return (
    <div className="faq">
      <div className="sect_1">
        <Header />
        <div className="wrpr">
          <h1>Hello, How can we help you?</h1>
          <p>Email us: support@bumpme.in</p>
        </div>
      </div>
      <div className="sect_2">
        <h1>FAQs</h1>
        <p className="info">
          TO SCAN YOUR BUMP, ALL YOU NEED IS NFC ENABLED SMARTPHONE - NO APP
          REQUIRED
        </p>
        <div>
          {data.map((item) => (
            <FaqItem item={item} />
          ))}
          <p style={{ paddingTop: 20 }}>
            Still need help? Email us at support@bumpme.in or ping us on
            instagram @bumpme.in
          </p>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
