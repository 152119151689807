import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import SocialBtnComponent from "../../components/socialBtnComponent/SocialBtnComponent";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";

import firebase from "../../firebase";
import "./userProfile.css";

import userImage from "../../assets/images/account.png";
import { LinkHandler } from "../../utils/linkHandler";
import BumpLogo from "../../assets/images/logo.png";
import UpArrow from "../../assets/images/up_arrow.png";
import { Redirect } from "react-router-dom";
function UserProfile() {


  const { code } = useParams();
  const history = useHistory();
  let linkRef = useRef();

  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());

  const [socialMedia, setSocialMedia] = useState([]);
  const [publicUsername, setPublicUsername] = useState();
  const [profImgExtension, setProfImgExtension] = useState();
  const [imageURL, setImageURL] = useState();
  const [direct, setDirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [designation, setDesignation] = useState("");
  const [lastScroll, setLastScroll] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);

  const redirectHandler = (username) => {
    if (username) {
      history.push("/" + username);
      window.location.reload(false);
    } else {
      getProfileDetails(code);
    }
  };

  window.onscroll = () => setScrollPosition(window.scrollY);

  useEffect(() => {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > lastScroll) {
      console.log("down");
      // downscroll code

      OS != "MacOS" && window.scrollBy(0, window.innerHeight);
    } else {
      // upscroll code
      OS != "MacOS" && window.scrollBy(0, -window.innerHeight);
    }
    setLastScroll(st <= 0 ? 0 : st); // For Mobile or negative scrolling

    // if (window.scrollY !== 0 && window.scrollY !== 100) {
    //     window.scrollTo({ top: window.innerHeight })
    // }
  }, [window.scrollY]);



  const getProfileDetails = (username) => {
    // to get details if username directly given on link.
    let tempExtension = "";
    try {
      firebase
        .firestore()
        .collection("Users")
        .where("username", "==", username)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if (doc.data().profStatus) {
              setSocialMedia(doc.data().socialMedia);
              setPublicUsername(doc.data().publicUsername);
              setProfImgExtension(doc.data().profImgExtension);
              setDirect(doc.data().direct);
              setUsername(doc.data().username);
              setDesignation(doc.data().designation);

              tempExtension = doc.data().profImgExtension;

             

            } else {
              history.replace('/profile-off')
            }

          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        })
        .then(() => {
          getProfPic(username, tempExtension);
        });
    } catch (error) {
      console.log(error);
    }
  };

 

  const getOperatingSystem = (window) => {
    let operatingSystem = "Not known";
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      operatingSystem = "WindowsOS";
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }

    return operatingSystem;
  };

  const OS = getOperatingSystem(window);

  // to get profile details
  useEffect(() => {
    let tempUsername;
    try {
      firebase
        .firestore()
        .collection("dataEntry")
        .where("code", "==", code)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            tempUsername = doc.data().name;
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        })
        .then(() => {
          redirectHandler(tempUsername);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getProfPic = async (username, extension) => {
    try {
      let imageRef = username + "." + extension;
      const url = await firebase.storage().ref(imageRef).getDownloadURL();
      setImageURL(url);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const directRedirection = async () => {
    await LinkHandler(socialMedia[0], publicUsername).then(() => {
      history.push('/')
    })
  }

  useEffect(async () => {

    if (direct) {
      LinkHandler(socialMedia[0], publicUsername).then(() => {

      })
    }



  }, [loading, direct])
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <h1>Loading...</h1>
      </div>
    );
  }
  if (code == 'g9iwahyuv7') {
    window.open("https://bumpme.in/mr.syed.fakruddin", '_self')
  } else {
    if (socialMedia.length == 0 && !loading) {
      window.open("https://shop.bumpme.in/", '_self');
    }
  }


  return (
    <div>
      {direct && <div onclick={directRedirection()} ></div>}
      <div className="userProfile">
        <div
          className="sub_1"
          style={{
            resize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <img
            src={BumpLogo}
            className="logo"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/");
              window.scrollTo(0, 0);
            }}
          />
          {/* <img className='profilePic' src={imageURL ? imageURL : userImage} alt="profile image" srcset="" /> */}
          <div className="usernameWrpr">
            <p className="publicUsername"> {publicUsername}</p>
            <p className="designation"> {designation}</p>
          </div>
        </div>

        <div className="socialContainer">
          {direct ? (
            <SocialBtnComponent
              item={socialMedia[0]}
              publicUsername={publicUsername}
            />
          ) : (
            socialMedia.map((item) => (
              <SocialBtnComponent item={item} publicUsername={publicUsername} />
            ))
          )}
        </div>
        {/* <button className='save' onClick={() => window.open(`bump://${username}`, '_blank')} >Save</button> */}
      </div>
      <div className="mob_userProfile" style={{}}>
        <div
          className="sub_1"
          style={{
            backgroundImage:
              OS != "MacOS" &&
              `linear-gradient(180deg, rgba(
              71,132,225 ,0), rgba(
              71,132,225, 0.9)), url(${imageURL ? imageURL : userImage})`,


            backgroundRepeat: "no-repeat",
            backgroundPosition: OS == "MacOS" ? "top" : "center",
            backgroundSize: OS == "MacOS" ? "auto 100vh" : "cover",
            backgroundAttachment: OS == "MacOS" ? "scroll" : "fixed",
            WebkitOverflowScrolling: OS == "MacOS" && "touch",
          }}
        >
          <div
            className="wrpr"
            style={{
              backgroundImage:
                OS == "MacOS" &&
                `linear-gradient(180deg, rgba(
              71,132,225 ,0), rgba(
              71,132,225, 0.9)), url(${imageURL ? imageURL : userImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="usernameWrpr">
              {/* <p className="publicUsername">   {publicUsername}</p>
                                        <p className="designation">   {designation}</p> */}
              <div
                style={{
                  width: "100vw",

                  marginTop: window.innerHeight * 0.6,
                  // marginLeft: -85,
                  // marginRight: -90,
                  visibility: scrollPosition < 20 ? "visible" : "hidden",
                }}
              >
                <p className="publicUsername"> {publicUsername}</p>
                <p className="designation"> {designation}</p>
              </div>

              <div style={{ marginTop: 40 }}>
                <img src={UpArrow} className="arrow" color="white" />
                <p className="scroll">Scroll up to connect</p>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage:
                OS == "MacOS"
                  ? `linear-gradient(180deg, rgba(
                71,132,225 ,0.88), rgba(
                71,132,225, 0.88)), url(${imageURL ? imageURL : userImage})`
                  : `linear-gradient(180deg, rgba(
                  71,132,225 ,0), rgba(
                  71,132,225, 0.9)), url(${imageURL ? imageURL : userImage})`,
              ...(OS != "MacOS" && {
                backgroundRepeat: "no-repeat",
                resize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
              }),
              // backgroundPosition: OS == "MacOS" ? "top" : "center",
              // backgroundSize: OS == "MacOS" ? "auto 100vh" : "cover",
              // backgroundAttachment: OS == "MacOS" ? "scroll" : "fixed",
              // WebkitOverflowScrolling: OS == "MacOS" ? "touch" : "none",
              // WebkitBackdropFilter: "blur(20px) contrast(60%)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: OS == "MacOS" && "103vh",
            }}
            className="scrollbar"
          >
            <div
              className="scrollbarr"
              style={{ backdropFilter: "blur(10px)" }}
            >
              <div className="sub_2">
                <div
                  style={{
                    display: "flex",
                    width: "100",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    // paddingTop: window.innerHeight * 0.1,
                  }}
                  className="username"
                >
                  <p className="publicUsername"> {publicUsername}</p>
                  <p className="designation"> {designation}</p>
                </div>
                <div className="socialContainer">
                  {socialMedia.map((item) => (
                    <SocialBtnComponent
                      item={item}
                      publicUsername={publicUsername}
                    />
                  ))}
                </div>

                <img
                  src={BumpLogo}
                  className="logo"
                  onClick={() => {
                    window.open('https://shop.bumpme.in/', "_self")
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
