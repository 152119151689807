import React, { useEffect } from 'react'
import axios from 'axios'
import { sha512 } from 'js-sha512'
import { useLocation } from 'react-router-dom'
function Payment() {

    const location = useLocation()

    const { name, email, phno, address, type, customName } = location.data

    const hashFunction = (key, txnid, amount, productinfo, firstname, email, udf1, udf2, udf3, udf4, udf5) => {

        let SALT = 'UkojH5TS'
        let hashKey = (key + '|' + txnid + '|' + amount + '|' + productinfo + '|' + firstname + '|' + email + '|||||||||||' + SALT)
        return sha512(hashKey);
    }

    let Key = 'oZ7oo9';
    let Txnid = Date.now().toLocaleString() // this id is to be unique for every transaction.
    let amount = '200.0'
    let productinfo = type
    let firstname = name
    // let email = 'ans@gm.com'
    let phone = phno
    let lastname = name
    let surl = 'http://localhost:3001/response.html'
    let furl = 'http://localhost:3001/response.html'

    let udf1 = productinfo
    let udf2 = email
    let udf3 = phone
    let udf4 = 'abvlkjsdfj asdf'
    let udf5 = Txnid

    let hash = hashFunction(Key, Txnid, amount, productinfo, firstname, email, udf1, udf2, udf3, udf4, udf5)
    console.log(hash)




    const Comp = ({ label, value }) => {
        return (
            <div style={{
                display: 'flex',


            }} >
                <p
                    style={{ width: '50%' }}
                >{label}</p>
                <p>{value}</p>
            </div>
        )
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >

            <div style={{ width: '50%' }} >
                <h1>Details</h1>
                <Comp label='Name' value={name} />
                <Comp label='Email' value={email} />
                <Comp label='Phone Number' value={phno} />
                <Comp label='Type' value={type} />
            </div>





            <form action='https://test.payu.in/_payment'
                method='post'
                style={{ width: '50%', }}
            >














                <input type='hidden' name="firstname" value={firstname} />
                <input type='hidden' name="lastname" value={lastname} />
                <input type='hidden' name="phone" value="9999999999" />
                <input type='hidden' name="email" value={email} />
                <input type='hidden' name="Key" value={Key} />

                <input type='hidden' name="surl" value={surl} />
                <input type='hidden' name="hash" value={hash} />

                <input type='hidden' name="curl" value={surl} />
                <input type='hidden' name="furl" value={furl} />
                <input type='hidden' name="txnid" value={Txnid} />
                <input type='hidden' name="productinfo" value={productinfo} />
                <input type='hidden' name="amount" value={amount} />

                <input type="submit" value="submit" />
            </form>
        </div>
    )
}

export default Payment
