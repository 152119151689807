import React, { useState } from 'react'
import NewBtn from '../../components/btn/NewBtn'
import Navigation from '../../components/Header/Navigation'
import './deleteAccount.css'
import firebase, { auth } from '../../firebase'
import { useHistory } from 'react-router-dom'
const DeleteAccount = () => {

    const history = useHistory()

    const [pwd, setPwd] = useState('')
    const [loading, setLoading] = useState(false)


    const deleteHandler = async () => {
        if (!pwd) return alert('Enter Password.')

        auth.signInWithEmailAndPassword(auth.currentUser.email, pwd)
            .then(() => {
                moveAthleteToDelete()
                    .then(async () => {
                        //Delete user from auth
                        await auth.currentUser.delete();


                        // Remove user data from context
                        alert('User Deleted')

                        // Redirect user to init screen
                        history.push('/user_login')
                    })
                    .catch(error => {
                        setLoading(false)
                        alert('Error Occured, Please try again')
                    })


            })
            .catch((e) => alert('Email and password not match'))

    }


    const moveAthleteToDelete = async () => {

        const email = auth.currentUser.email

        return new Promise(async (resolve, reject) => {
            await firebase
                .firestore()
                .collection("Users")
                .where("email", "==", email)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(async (doc) => {
                        await firebase.firestore()
                            .collection('DeletedAccounts')
                            .doc(doc.id)
                            .set({ ...doc.data() })
                            .then(async () => {
                                console.log('User created in deletedAccounts');
                                await firebase.firestore()
                                    .collection('Users')
                                    .doc(doc.id)
                                    .delete()
                                    .then(() => resolve())
                                    .catch(error => reject(error))
                            })
                            .catch(error => {
                                reject(error);
                                console.log(error);
                            });
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })
                .then(() => {
                    // getProfPic(username, tempExtension);
                });

        });
    }


    if (loading) return <div><h1>Loading...</h1></div>

    return (
        <div className='delete_account' >
            <Navigation label='Delete Account' />
            <div className='sub' >
                <p>Enter Password</p>

                <input type='password' className='input' value={pwd} onChange={e => setPwd(e.target.value)} />

                <div style={{ height: '50vh' }} />

                <NewBtn
                onClick={deleteHandler}
                    containerStyle={{ backgroundColor: 'red', widht: '90%', margin: 'auto' }}
                    label='Delete Account'
                />
            </div>
        </div>
    )
}

export default DeleteAccount