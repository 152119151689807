import React from "react";
import { useHistory } from "react-router-dom";
import "./contact.css";
const Contact = () => {

  const history = useHistory()

  return (
    <div className="contactMain">
      <div className="contactOuter">
        <div className="us">
          <div>
            <h1 className="title">Contact Us</h1>
            <h1 className="contactSubtxt">
              Need help with your order? Let us take care of you.
            </h1>
            <p>Call/WhatsApp us: +91 9535557350 (Mon-Sat 10 AM to 6 PM)</p>
            <p>Email us: support@bumpme.in (Preferred)</p>
            <p>All queries are responded within 24 hours on business days.</p>
            <p>
              Alternate way
              <br />
              Click the button 
            </p>
          </div>
        </div>
        <div className="contact">

          <div
            onClick={() => window.open('https://shopbumpme.in/contact-us')}
            className='contact_btn'
          >
            <p className='contact_btn_text'>Contact us</p>

          </div>

          {/* <div
            style={{
              backgroundColor: "#EDF3FC",
              width: "333px",
              height: "378px",
              marginTop: "120px",
              boxShadow: "4px 4px 40px rgba(115, 115, 115, 0.25)",
            }}
          >
            <h1
              style={{
                paddingLeft: 30,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              Contact
            </h1>
            <h1
              style={{
                paddingLeft: 30,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Name
            </h1>
            <input
              style={{
                marginLeft: 30,
                width: "281px",
                height: "24px",
                border: 'none'
              }}
            />
            <h1
              style={{
                paddingLeft: 30,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Email
            </h1>
            <input
              style={{
                marginLeft: 30,
                width: "281px",
                // borderRadius: "4px",
                height: "24px",
                border: 'none'
              }}
            />
            <h1
              style={{
                paddingLeft: 30,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Phone Number
            </h1>
            <input
              style={{
                marginLeft: 30,
                width: "281px",
                height: "24px",
                border: 'none'
              }}
            />
            <h1
              style={{
                paddingLeft: 30,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Message
            </h1>
            <textarea
              placeholder="Type your message.."
              style={{
                marginLeft: 30,
                width: "281px",
                borderRadius: "4px",
                height: "80px",
                borderColor: '#EDF3FC',
              }}
            />
            <div
              style={{
                marginLeft: 30,
                width: "283px",
                height: "39px",
                backgroundColor: "#4784E1",
                borderRadius: "50px",
                marginTop: 10,
                textAlign: "center",
              }}
            >
              <h1
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  paddingTop: 8,
                }}
              >
                Submit
              </h1>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
