import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import "./bump.css";
import facebook from "../../assets/images/web/facebook.png";
import instagram from "../../assets/images/web/instagram.png";
import twitter from "../../assets/images/web/twitter.png";
import youtube from "./images/youtube.png";
import BumpLogo from "../../assets/images/logo.png";
import AppleStoreLogo from "../../assets/images/web/appleStoreLogo.png";
import PlayStoreLogo from "../../assets/images/web/playStoreLogo.png";
const Bump = () => {
  const history = useHistory();
  return (
    <div
      style={{
        backgroundColor: "#20437A",
        height: "100%",
        width: "100%",
        // paddingBottom: 25,
        // marginTop: 75,

      }}
    >
      <div className="footerMain">
        <div
          className="footer-sub"
          style={{
            display: "flex",
            paddingTop: "15px",
            alignItems: "center",
            // backgroundColor: 'yellow',

          }}
        >
          <img src={BumpLogo} style={{ width: 200 }} alt="" srcset="" />
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p style={{ color: "white" }}>Connect with us</p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  backgroundColor: "#E3E3E3",
                  borderRadius: 60,

                  cursor: "pointer",
                }}
              >
                 <a
                style={{
                  textDecoration: "none",
                }}
                href={"https://www.facebook.com/bumpmein"}
              >
                <img src={facebook} width="36px" />
                </a>
              </div>
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  backgroundColor: "#E3E3E3",
                  borderRadius: 60,

                  marginLeft: 20,
                  cursor: "pointer",
                }}
              >
                  <a
                style={{
                  textDecoration: "none",
                }}
                href={"https://www.instagram.com/bumpme.in/"}
              >
                <img src={instagram} width="36px" />
                </a>
              </div>
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  backgroundColor: "#E3E3E3",
                  borderRadius: 60,

                  marginLeft: 20,
                  cursor: "pointer",
                }}
              >
                <img
                  src={twitter}
                  width="36px"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // marginLeft: 600,
            display: "flex",
            justifyContent: 'space-around',
            // backgroundColor: 'yellow',
            paddingTop: "40px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "15px",
            lineHeight: "30px",
            color: "#FFFFFF",
            marginLeft: "20px",
            width: '60%'
          }}
        >
          <div style={{}}>
            <h1
              onClick={() => {
                history.push("/how-to");
                window.scroll(0, 0);
              }}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "11px",
                lineHeight: "30px",
                color: "#FFFFFF",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              How To Use
            </h1>

            <h1
              onClick={() => window.open("https://shopbumpme.in/contact-us", '_self')}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "30px",
                color: "#FFFFFF",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Products
            </h1>

            <h1
               onClick={() => window.open("https://shopbumpme.in/contact-us", '_self')}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "30px",
                color: "#FFFFFF",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Help
            </h1>

            {/* <h1
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "30px",
                color: "#FFFFFF",
              }}
            >
              Label 1
            </h1> */}
            {/* <h1
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "30px",
                color: "#FFFFFF",
              }}
            >
              Label 1
            </h1> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "30px",
                color: "#FFFFFF",
                cursor: "pointer",
                marginTop: "10px",
                textDecoration: "none",
              }}
            >
              Download Now
            </div>
            <div
              style={{
                padding: 20,
                alignItems: "center",
                display: "flex",
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                }}
                href={"https://apps.apple.com/us/app/bump-nfc/id1594000760"}
              >
                <img
                  src={AppleStoreLogo}
                  style={{ width: 40, cursor: "pointer" }}
                />
              </a>

              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.bump.nfc"
                }
                style={{
                  textDecoration: "none",
                }}
              >
                <img
                  src={PlayStoreLogo}
                  style={{
                    paddingLeft: 20,
                    width: 40,

                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          </div>
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "30px",
              color: "#FFFFFF",
              cursor: "pointer",
              marginTop: "10px",
              marginLeft: "10px",
              textDecoration: "none",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                color: "#FFFFFF",
              }}
              href="https://m.gsmarena.com/results.php3?chkNFC=selected&sFreeText=fingerprint"
            >
              Check for NFC Enabled Phones
            </a>
          </div>
        </div>
      </div>
      <div className="Line">
        <hr
          // width="1287px"
          color="#FFFFFF"
          style={{
            marginTop: 15,
          }}
        />
      </div>
      <div className="Footer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: 'column',
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              width: "90%",
              cursor: "pointer",
              justifyContent: 'center'
            }}
          >
            <h1
              onClick={() => history.push("/privacy")}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                color: "#FFFFFF",
                marginRight: 10,

              }}
            >
              Privacy policy
            </h1>
            {/* <div style={{ width: '1px', backgroundColor: 'white' }} ></div> */}
            <h1
              onClick={() => history.push("/terms")}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "170%px",
                color: "#FFFFFF",
                marginRight: 10
              }}
            >
              Terms and Conditions
            </h1>
            <h1
              onClick={() => history.push("/refund-policy")}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "170%px",
                color: "#FFFFFF",
              }}
            >
              Refund Policy
            </h1>
          </div>
          <h1
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "170%px",
              color: "#FFFFFF",
            }}
          >
            All Rights Reserved | Bump 2021 ©
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Bump;
