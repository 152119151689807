import React from 'react'
import BackIco from '../../../assets/images/back.png'
const SettingsItem = ({ label, onClick }) => {


    return (
        <div
            style={{
                width: "100%",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer'
                // backgroundColor: 'red'
            }}
            onClick={onClick}
        >
            <p>{label}</p>
            <img src={BackIco} style={{ width: 20, height: 20, transform: 'rotate(180deg)' }} />
        </div >
    )
}

export default SettingsItem