import React, { useContext, useEffect, useRef, useState } from "react";
import LoginPage from "./pages/login/LoginPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from "./pages/dashBoard/Dashboard";
import firebase, { useAuth } from "./firebase";
import SignUpPage from "./pages/SignUp/SignUpPage";
import UserProfile from "./pages/userProfile/UserProfile";
import Payment from "./pages/payment/Payment";

import Bump from "./components/Bump/Bump";
import Contact from "./components/Contact/Contact";
import Custom from "./components/Custom/Custom";
import Home from "./components/Home/Home";
import MainIntro from "./components/Intro/MainIntro";
import Products from "./components/Products/Products";
import Products1 from "./pages/products/Products1";
import WhyMain from "./components/Why/WhyMain";
import Privacy from "./pages/terms and privacy/Privacy";
import Terms from "./pages/terms and privacy/Terms";
import Faqs from "./pages/faqs/Faqs";
import RefundPolicy from "./pages/refundPolicy/RefundPolicy";
import Cart from "./pages/products/Cart";
import Activate from "./components/Activate/Activate";
import HowTo from "./pages/howTo/HowTo";
import ProfileOf from "./pages/profileOff/ProfileOf";
import { useHistory } from "react-router-dom";
import Login from "./pages/auth/Login";
import Account from "./pages/account/Account";
import AuthHome from "./pages/auth/AuthHome";
import OTPScreen from "./pages/auth/OTPScreen";
import SignUp from "./pages/auth/SignUp";
import CreateUsername from "./pages/createUserName/CreateUsername";
import SetupProfile from "./pages/setupProfile/SetupProfile";
import Settings from "./pages/settings/Settings";
import DeleteAccount from "./pages/deleteAccount/DeleteAccount";
import StaffLogin from "./pages/login/StaffLogin";
import StaffDashboard from "./pages/staffDashboard/StaffDashboard";
import AddStaff from "./pages/addStaff/AddStaff";
import { AppContext } from "./contexts/AppContext";

const App = () => {
  const { user, setUser } = useAuth();
  const productsRef = useRef();


  return (

    <Switch>
      <Route exact path="/">
        <div
          style={{
            width: "100%",
          }}
        >
          <Home productsRef={productsRef} />
          <MainIntro />
          <Activate />
          <Products productsRef={productsRef} />
          <WhyMain />
          <Custom />
          <Contact />
          <Bump />
        </div>
      </Route>

      <Route exact path="/help">
        <Faqs />
      </Route>
      <Route exact path="/products">
        <Products1 />
      </Route>

      <Route exact path="/cart">
        <Cart />
      </Route>
      <Route exact path="/admin">
        {user?.email=='harsha.baskaran@gmail.com' ? <Dashboard /> : <LoginPage />}
      </Route>
      <Route exact path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/signUp">
        <SignUpPage />
      </Route>

      <Route exact path="/payment/do">
        <Payment />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <Route exact path="/refund-policy">
        <RefundPolicy />
      </Route>
      <Route exact path="/how-to">
        <HowTo />
      </Route>
      <Route exact path="/a1005625">
        <HowTo />
      </Route>
      <Route exact path="/profile-off">
        <ProfileOf />
      </Route>

      <Route exact path="/login_home">
        <AuthHome />
      </Route>
      <Route exact path="/user_login">
        <Login />
      </Route>

      <Route exact path="/sign_up">
        <SignUp />
      </Route>
      <Route exact path="/otp">
        <OTPScreen />
      </Route>
      <Route exact path="/create_username">
        <CreateUsername />
      </Route>

      <Route exact path="/setup_profile">
        <SetupProfile />
      </Route>

      <Route exact path="/account_details">
        <Account />
      </Route>

      <Route exact path="/settings">
        <Settings />
      </Route>
      <Route exact path="/delete_account">
        <DeleteAccount />
      </Route>

      <Route exact path="/staff_login">
        <StaffLogin />
      </Route>

      <Route exact path="/staff_dashboard">
        <StaffDashboard />
      </Route>

      <Route exact path="/add_staff">
        <AddStaff />
      </Route>

      {/* <Route exact path="/new-screen">
          <NewScreen />
        </Route> */}
      <Route exact path="/:code">
        <UserProfile />
      </Route>
    </Switch>
  );
};

export default App;
