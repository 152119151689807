import React, { useContext } from 'react'
import Btn from '../../components/btn/Btn'
import './auth.css'
import LogoImg from '../../assets/images/logo.png'
import { useState } from 'react'
import { auth } from '../../firebase'
import { useHistory } from 'react-router-dom'
import AuthHeader from './components/AuthHeader'
import NewBtn from '../../components/btn/NewBtn'
import { AppContext } from '../../contexts/AppContext'
const Login = () => {

    const {setAdmin}=useContext(AppContext)

    const history = useHistory()

    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')

    const loginHandler = async () => {
        if (!email || !pwd) return alert('Enter Email and Password.')
        auth.signInWithEmailAndPassword(email, pwd)
            .then(() => {
                setAdmin(null)
                history.push('/account_details')

            })
            .catch((e) => {
                console.log(e)
                alert('Email and password not match')
            })

    }

    return (
        <div className='login' >

            <AuthHeader label='Log In with Email' />

            <div className='sub'>
                <div className='auth_input_wrpr'>
                    <input
                        type="text"
                        className='auth_input'
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email'

                    />
                </div>
                <div className='auth_input_wrpr'>
                    <input
                        type="password"
                        className='auth_input'
                        onChange={(e) => setPwd(e.target.value)}
                        placeholder='Password'
                    />
                </div>
                <p className='forgot' >Forgot Password?</p>
                <NewBtn onClick={loginHandler} label={'Log In'} containerStyle={{ marginTop: 50, width: '100%' }} />
            </div>

            {/* <div className='sub' >

                <img src={LogoImg} className='logo' />

                <h1 className='title' >Login</h1>

                <div  >
                    <p>Email</p>
                    <input
                        type="text"
                        className='input'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div style={{ marginTop: 20 }} >
                    <p>Password</p>
                    <input
                        type="text"
                        onChange={(e) => setPwd(e.target.value)}
                        className='input'
                    />
                </div>

                <Btn onClick={loginHandler} label={'Login'} containerStyle={{ margin: 20 }} />
            </div> */}
        </div>
    )
}

export default Login