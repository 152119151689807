import React, { useEffect, useState } from 'react'
import './dashboardStyle.css'
import firebase, { useAuth } from '../../firebase'

function HomePage() {
    const { logout } = useAuth()

    const [id, setId] = useState('')
    const [docId, setDocId] = useState('')
    const [username, setUsername] = useState('')
    const [code, setCode] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [editing, setEditing] = useState(false)
    const [validation, setValidation] = useState({ username: true, id: true, code: true })

    const SignoutHandler = () => {
        logout()
    }

    const editHandler = (username, id, docId, code) => {
        setUsername(username)
        setId(id)
        setCode(code)
        setDocId(docId)
        setEditing(true)
    }


    const addEntry = () => {

        setLoading(true)
        try {
            firebase.firestore().collection('dataEntry')
                .doc()
                .set({
                    id,
                    name: username,
                    code
                }).then(() => {
                    setUsername('')
                    setId('')
                    setDocId('')
                    setCode('')
                    getData()
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })

        } catch (error) {
            console.log(error)
        }

    }
    const editEntry = () => {
        setLoading(true)
        try {
            firebase.firestore().collection('dataEntry')
                .doc(docId)
                .update({
                    id,
                    name: username,
                    code
                }).then(() => {
                    setUsername('')
                    setId('')
                    setDocId('')
                    setCode('')
                    getData()
                    setEditing(false)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })

        } catch (error) {
            console.log(error)
        }
    }
    const deleteEntry = (docId) => {
        setLoading(true)
        try {
            firebase.firestore().collection('dataEntry')
                .doc(docId)
                .delete().then(() => {
                    setUsername('')
                    setId('')
                    setDocId('')
                    setCode('')
                    getData()
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })

        } catch (error) {
            console.log(error)
        }
    }
    const getData = async () => {
        setLoading(true)
        let tempData = [];
        try {
            await firebase.firestore().collection('dataEntry')
                .get()
                .then((querySnapshot) => {

                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        tempData.push({ ...doc.data(), docId: doc.id })
                    });
                    setData(tempData)
                }).then(() => {
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })


        } catch (error) {
            console.log(error)
        }

    }

    const validationHandler = async (type) => {
        if (!code || !id) return alert('Id and code are mandatory')
        let usernameExist = false
        let idExist = false
        let codeExist = false


        // let promise1 = await firebase.firestore().collection("dataEntry").where('code', '==', code)
        //     .get()
        //     .then((querySnapshot) => {
        //         if (!querySnapshot.empty) {
        //             codeExist = true
        //             querySnapshot.forEach((doc) => {
        //                 if (doc.id == docId) codeExist = false

        //             })
        //         }


        //     })
        let promise2 = await firebase.firestore().collection("dataEntry").where('id', '==', id)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    idExist = true
                    querySnapshot.forEach((doc) => {
                        if (doc.id == docId) idExist = false
                    })
                }
            })
        // let promise3 = await firebase.firestore().collection("dataEntry").where('name', '==', username)
        //     .get()
        //     .then((querySnapshot) => {
        //         console.log(!querySnapshot.empty, 'username1')
        //         if (!querySnapshot.empty) {
        //             usernameExist = true
        //             querySnapshot.forEach((doc) => {
        //                 if (doc.id == docId) usernameExist = false
        //                 if (!username) usernameExist = false
        //             })
        //         }
        //     })
        // let promise4 = await firebase.firestore().collection("Users").where('username', '==', code)
        //     .get()
        //     .then((querySnapshot) => {
        //         console.log(!querySnapshot.empty, 'username2')
        //         if (!querySnapshot.empty) {
        //             usernameExist = true

        //         }
        //     })
      
        await Promise.all([ promise2,]).then(() => {
            if ( idExist) {
                return window.alert('Provide unique username')
            }
            if (type == 'add') {
                addEntry()
            } else {
                editEntry()
            }
        })

    }

    useEffect(() => {
        getData()
    }, [])

    if (loading) {
        return (
            <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
            >
                <h1>Loading...</h1>
            </div>
        )
    }
    return (
        <div className='dB-container'>
            <button className='signout' onClick={() => SignoutHandler()}>Signout</button>
            <div className='input-wrpr' >
                <input type="text"
                    placeholder='Id'
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                />
                <input type="text"
                    placeholder='Code'
                    onChange={(e) => setCode(e.target.value.toString().toLowerCase())}
                    value={code}
                />
                <input type="text"
                    placeholder='User name'
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                />
                <button
                    onClick={() => { editing ? validationHandler('edit') : validationHandler('add') }}
                >{editing ? 'Edit' : 'Add'}</button>
            </div>
            <div className="dB-table">
                <table>
                    <tr>
                        <th>Id</th>
                        <th>Code</th>
                        <th>Username</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    {data.map((item) => (
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.code}</td>
                            <td>{item.name}</td>
                            <td><button onClick={() => editHandler(item.name, item.id, item.docId, item.code)} >Edit</button></td>
                            <td><button onClick={() => deleteEntry(item.docId)} >Delete</button></td>
                        </tr>
                    ))}


                </table>
            </div>
        </div>
    )
}

export default HomePage
