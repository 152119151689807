import React from 'react'

function RefundPolicy() {
    return (
        <div>
            <p>Refund policy<br />Returns<br />Our policy lasts 7 days. If 7 days have gone by since your purchase, unfortunately we can&rsquo;t offer you a refund or exchange.</p>
            <p>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>
            <p>To complete your return, we require a receipt or proof of purchase.<br />Please do not send your purchase back to the manufacturer.<br />Any item not in its original condition, is damaged or missing parts for reasons not due to our error<br />- Any item that is returned more than 15 days after delivery are not liable for partial return</p>
            <p>Refunds (if applicable)<br />Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.<br />If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p>
            <p>Late or missing refunds (if applicable)<br />If you haven&rsquo;t received a refund yet, first check your bank account again.<br />Then contact your credit card company, it may take some time before your refund is officially posted.<br />Next contact your bank. There is often some processing time before a refund is posted.<br />If you&rsquo;ve done all of this and you still have not received your refund yet, please contact us at support@frontcart.in</p>
            <p>Sale items (if applicable)<br />Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</p>
            <p>Exchanges (if applicable)<br />We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at support@frontcart.in. We will let you know the return address and instructions on how to return your item.</p>
        </div>
    )
}

export default RefundPolicy
