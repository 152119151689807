import React from 'react'
import { useHistory } from 'react-router-dom'
import BackIco from '../../../assets/images/back.png'
const AuthHeader = ({ label }) => {

    const history = useHistory()

    return (
        <div className='auth_header' >
            <img
                onClick={() => history.goBack()}
                src={BackIco}
                style={{ width: 25, height: 25,  marginRight: 10, cursor: 'pointer' }}
            />
            <p>{label}</p>
        </div>
    )
}

export default AuthHeader