import firebase from 'firebase';
import "firebase/firestore"
import "firebase/auth";
import { createContext, useContext, useEffect, useState } from 'react';

var firebaseConfig = {
    apiKey: "AIzaSyCZzx_31WS3tj7pFOxcPtzwXlTPiN1LMAM",
    authDomain: "sample-7be24.firebaseapp.com",
    projectId: "sample-7be24",
    storageBucket: "sample-7be24.appspot.com",
    messagingSenderId: "412907293114",
    appId: "1:412907293114:web:ce9cc5793e547873c67583"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;

const AuthContext = createContext()

export const auth = firebase.auth()

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [isAuthenticating, setIsauthenticating] = useState(true)


    const logout = () => {
        return firebase.auth().signOut().then(() => {
            setUser(null)
        })
    }

    const googleSignIn = () => {
        let auth = firebase.auth();
        const googleProvider = new firebase.auth.GoogleAuthProvider()

        auth.signInWithPopup(googleProvider).then((res) => {
            console.log(res.user)
            setUser(res.user)
            return true
        }).catch((error) => {
            console.log(error.message)
        })

    }

    // subscribe user on mount to rerender any component with latest auth.
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            setUser(user)
            setIsauthenticating(false)
        })
        // cleanup subscription on unmount
        return () => unsubscribe()
    }, [])

    const values = {
        setUser,
        user,
        isAuthenticating,
        logout,
        googleSignIn

    }
    return (
        <AuthContext.Provider value={values} >
            {!isAuthenticating && children}
        </AuthContext.Provider>
    )
}