import React, { useEffect, useState } from "react";
import "./Header.css";
import { useHistory } from "react-router";
import BumpLogo from "../../assets/images/logo.png";
import BumpBlack from "./bump_black.png";
const Header = () => {
  const history = useHistory();

  const [showBackground, setShowBackground] = useState(history.location.pathname == '/how-to' ? true : false)

  const listenToScroll = () => {
    let heightToHideFrom = window.innerHeight;
    const winScroll = window.scrollY

    if (history.location.pathname == '/how-to') {
      return setShowBackground(true)
    }

    if (winScroll > heightToHideFrom) {
      setShowBackground(true);
    } else {

      setShowBackground(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  return (
    <div className="header"
      style={{ ...(showBackground && { backgroundImage: ` linear-gradient(#163d77, rgba(61, 58, 219, 0.6))` }) }}
    >
      <div className="header_logo">
        {console.log(history.location.pathname)}
        {history.location.pathname === "/products" ? (
          <img
            style={{ width: 100 }}
            src={BumpBlack}
            alt=""
            srcset=""
            onClick={() => {
              history.push("/");
              window.scroll(0, 0);
            }}
          />
        ) : (
          <img
            style={{ width: 100 }}
            src={BumpLogo}
            alt=""
            srcset=""
            onClick={() => {
              history.push("/");
              window.scroll(0, 0);
            }}
          />
        )}
      </div>

      <div className="header_right">
        <p
          style={{
            color:
              history.location.pathname === "/products" ? "black" : "white",
          }}
          onClick={() => window.open("https://shopbumpme.in/products", '_self')}
        >
          Products
        </p>
        <p
          style={{
            color:
              history.location.pathname === "/products" ? "black" : "white",
          }}
          onClick={() => {
            history.push("/how-to");
            window.scroll(0, 0);
          }}
        >
          How to Use
        </p>
        <div
          style={{
            cursor: "pointer",
            backgroundColor:
              history.location.pathname === "/products" && "#4784E1",
            color:
              history.location.pathname === "/products" && "white",
          }}
        >
          <p
          // style={{
          //   color: "black",
          //   fontFamily: "Poppins",
          //   fontStyle: "normal",
          //   fontWeight: "normal",
          //   fontSize: "16px",
          //   color: "#000000",
          //   width: "114px",
          //   paddingLeft: 16,
          // }}
          onClick={() => window.open("https://shopbumpme.in/products/ols/products/bump-custom", '_self')}>
            Custom Order
          </p>
        </div>
      </div>
    </div >
  );
};

export default Header;
