import React from "react";
import "./product.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import Img1 from "../../assets/images/web/products1.png";
import Img2 from "../../assets/images/web/products2.png";
import Img3 from "../../assets/images/web/products3.png";
import SwiperCore, { Navigation } from "swiper";
import { useHistory } from "react-router-dom";

SwiperCore.use([Navigation]);
const Products = ({ productsRef }) => {
  const width = window.innerWidth;
  const history = useHistory();
  const data = [
    {
      title: "TapTag",
      image: Img1,
    },
    {
      title: "TapCard",
      image: Img2,
    },
    {
      title: "Combo",
      image: Img3,
    },
    // {
    //   title: 'Keychains',
    //   image: Img1
    // },
  ];

  return (
    <div className="product-main"
      ref={productsRef}>
      <h1
        style={{
          marginTop: "70px",
          textAlign: "center",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "44px",
          lineHeight: "66px",
          color: "#434343",
        }}
      >
        Products
      </h1>
      <div
        className="outer"
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingBottom: 30
        }}
      >
        {width > 1024 ? (
          data.map((item) => (
            <div className="pdt-item">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 40,
                }}
              >
                {/* <div
                  className='pdt-div1'

                ></div> */}
                <img src={item.image} className="pdt-img" alt="" srcset="" />
                <h1 className="pdt-title">{item.title}</h1>
              </div>
            </div>
          ))
        ) : (
          <div className="swiper"
          >
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              navigation
            >
              {data.map((item) => (
                <SwiperSlide
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 700,
                  }}
                >
                  <div className="pdt-item">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 40,
                      }}
                    >
                      {/* <div
                        className='pdt-div1'

                      >

                      </div> */}
                      <img
                        src={item.image}
                        className="pdt-img"
                        alt=""
                        srcset=""
                      />
                      <h1 className="pdt-title">{item.title}</h1>
                    </div>
                    {/* <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div> */}
                  </div>

                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
      <div className="button_b">
        <div
          style={{
            width: "150px",
            backgroundColor: "black",
            margin: "0 auto",
            borderRadius: 24,
            textAlign: "center",
            cursor: "pointer",
            height: "50px",
          }}
          onClick={() => {
            window.open("https://shop.bumpme.in/", '_self')
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              paddingTop: "6px",
              fontWeight: 600,
              fontSize: "24px",
              color: "white",
              cursor: "pointer",
            }}
          >
            Buy Now
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Products;
