import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import firebase, { auth } from "../../firebase";
import StaffHeader from './components/StaffHeader';
import StaffItem from './components/StaffItem';
const StaffDashboard = () => {

    const history = useHistory()

    const adminAuth = auth.currentUser

    const { admin, setAdmin, setStaff } = useContext(AppContext)

    const [staffs, setStaffs] = useState([])
    const [codes, setCodes] = useState([])


    const getAllcodes = async () => {
        let codes = []
        try {
            await firebase
                .firestore()
                .collection("dataEntry")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // console.log(doc.data())
                        codes.push({ docId: doc.id, ...doc.data() })


                    });


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);

                })
            setCodes(codes)
        } catch (error) {
            console.log(error);
            alert('Error, Please try again')
        }

    }

    const getAllStaffs = async () => {

        let tempStaffs = []
        try {
            await firebase
                .firestore()
                .collection("Users")
                .where("adminId", "==", admin.id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // console.log(doc.data())
                        tempStaffs.push({ id: doc.id, ...doc.data() })
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })

            setStaffs(tempStaffs)

        } catch (error) {
            console.log(error);
            alert('Error, Please try again')
        }


    }

    const onStaffClick = (staff) => {
        setStaff(staff)
        history.push('/setup_profile')
    }



    useEffect(() => {
        if (admin?.id) {
            getAllStaffs()
            getAllcodes()
            setStaff(null)
        }
    }, [admin])

    return (
        <div>
            <StaffHeader staffs={staffs} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <table style={{ width: '80%', border: `1px solid gray` }} >
                    <tr

                    >
                        <th style={{ borderRight: `1px solid gray` }} >Username</th>
                        <th style={{ borderRight: `1px solid gray` }} >Email</th>
                        <th style={{ width: 150, borderRight: `1px solid gray` }} >Id</th>
                        <th style={{ width: 150, borderRight: `1px solid gray` }}>Code</th>
                        <th style={{ borderRight: `1px solid gray` }} >Edit</th>
                        <th style={{ marginLeft: 20, borderRight: `1px solid gray` }}>Config</th>
                        <th style={{ marginLeft: 20, }}>Delete</th>
                    </tr>
                    {
                        staffs.map((staff, id) => <StaffItem
                            id={id}
                            getAllcodes={getAllcodes}
                            codes={codes}
                            onStaffClick={onStaffClick}
                            staff={staff}
                            getAllStaffs={getAllStaffs}
                        />)
                    }

                </table>
            </div>
        </div>
    )
}

export default StaffDashboard