import React from "react";
import dollar from "./dollar.png";
import lock from "./lock.png";
import checkk from "./checkk.png";
import shield from "./shield.png";
import './why.css'
const WhyMain = () => {

  const txtData = [
    {
      img: dollar,
      t1: 'Save time and money',
      t2: 'Share info within seconds hassle free.',
      t3: 'Bump will eliminate the need to buy thousands of traditional  visiting cards. One BUMP, Infinite Sharing.'
    },
    {
      img: lock,
      t1: 'Your information is secure',
      t2: 'Your privacy and security is fully under your control. You can choose the level of security and the info to be shared.',
      t3: ''
    },
    {
      img: checkk,
      t1: 'Fully Compatible',
      t2: 'We support all devices with NFC receivers.',
      t3: ''
    },
    {
      img: shield,
      t1: 'Anti-theft',
      t2: 'Privacy mode will allow you to protect your confidential information.',
      t3: ''
    },

  ]


  return (
    <div
      className='whyMain'
    >
      <div
        className='whySub'

      >
        <h1 className='why-h1'>Why Bump?</h1>
      </div>
      <div className="why-container">
        <div className="why-1">
          {txtData.map((item) => (
            <div className="why-txtwrpr">

              <div className="img-why">
              <img
                className='why-img'
                src={item.img}
                alt="dollar"
                style={{ width: "20px", height: "20px", paddingTop: 10 }}
              />
              </div>
              <p
                className='why-t1'
              >
                {item.t1}
              </p>
              <p
                className='why-t2'

              >
                {item.t2}
              </p>
              <p
                className='why-t3'

              >
                {item.t3}
              </p>

            </div>
          ))}




        </div>
      </div>
      
    </div>
  );
};

export default WhyMain;
