import React, { useState, useEffect } from "react";
import Header from "../../components/Home/Header";
import "./Products.css";
import bump from "./images/bump.png";
import Bump from "../../components/Bump/Bump";
import custom from "./images/custom.png";
import normalWhite from "./images/normalWhite.png";
import normalBlack from "./images/normalBlack.png";
import stickerb from "./images/stickerb.png";
import stickerw from "./images/stickerw.png";
import custom1b from "./images/custom1b.png";
import custom1w from "./images/custom1w.png";
import custom2w from "./images/custom2w.png";
import custom2b from "./images/custom2b.png";
import custom3b from "./images/custom3b.png";
import custom3w from "./images/custom3w.png";
import combobb from "./images/combo_bb.png";
import combobw from "./images/combo_bw.png";
import comboww from "./images/combo_ww.png";
import combowb from "./images/combo_wb.png";
import sticker_white from "./images/sticker_white.png";
import combo from "./images/combo.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router";
import cross from "../../assets/images/cross.png";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
} from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import Cart from "./Cart";

const Products1 = () => {
  const [circleColor1, setCircleColor1] = useState(sticker_white);
  const [circleColor2, setCircleColor2] = useState("white");
  const [rectangle1, setRectangle1] = useState(normalWhite);
  const [rectangle2, setRectangle2] = useState(custom1w);
  const [rectangle3, setRectangle3] = useState(custom2w);
  const [rectangle4, setRectangle4] = useState(custom3w);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [noOfCartItems, setNoOfCartItems] = useState(0)


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phno, setPhno] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [color, setColor] = useState("white");
  const [qty1, setQty1] = useState(1);
  const [qty2, setQty2] = useState(1);
  const [qty3, setQty3] = useState(1);
  const [qty4, setQty4] = useState(1);
  const [qty, setQty] = useState(1);
  const [customName, setCustomName] = useState("");

  const [color1, setColor1] = useState("white");
  const [color2, setColor2] = useState("white");
  const [comboImage, setComboImage] = useState();


  const history = useHistory();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const data = {
    name: name,
    email: email,
    phno: phno,
    address: address,
    type: type,
    color: color,
    customName: customName,
    qty: qty,
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setName("");
    setEmail("");
    setPhno("");
    setAddress("");
    setCustomName("");
    setIsCustom(false);
  };

  const handleCloseDialog1 = () => {
    setOpenDialog1(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://sheet.best/api/sheets/f0ea51ff-fd54-4e9c-a22c-1a6096ec878f",
        data
      )
      .then((response) => {
        console.log(response);
        setOpenDialog(false);
        setName("");
        setEmail("");
        setPhno("");
        setAddress("");
        setCustomName("");
        setQty(1);
        history.push({
          pathname: "/payment/do",
          data,
        });
      });
  };

  useEffect(() => {
    if (color1 == "white" && color2 == "white") {
      setComboImage(comboww);
    } else if (color1 == "white" && color2 == "black") {
      setComboImage(combowb);
    } else if (color1 == "black" && color2 == "white") {
      setComboImage(combobw);
    } else if (color1 == "black" && color2 == "black") {
      setComboImage(combobb);
    }
  }, [color1, color2]);

  var a = [];

  if (localStorage.getItem("cartItems") != null) {
    a.push(localStorage.getItem("cartItems"));
    localStorage.setItem("cartItems", a);

  }
  useEffect(async () => {
    // console.log(localStorage.getItem("cartItems"), 'certi')
    // let items = localStorage.getItem("cartItems")
    // setNoOfCartItems(items ? items.length : 0)
    // console.log(noOfCartItems, 'cartno')
    var array = [];
    var uniqueChars;
    if (localStorage.getItem("cartItems") != null) {
      const data = localStorage.getItem("cartItems");
      array = data.split(",");
    }

    uniqueChars = array.filter((c, index) => {
      return array.indexOf(c) === index;
    });
    setNoOfCartItems(uniqueChars.length)
  })

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <div>
      <div
        className="products_screen"
        style={{
          overflowX: "hidden",
        }}
      >
        <Header />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="products_title">Products</div>
          <div
            className="cart"
            onClick={() => {
              setOpenDialog1(true);
            }}
          >
            <h3
              className="cart_title"
              style={{
                fontFamily: "Poppins",
                marginTop: "6px",
                fontWeight: 600,
                fontSize: "18px",
                color: "white",
                cursor: "pointer",
              }}
            >
              Cart
            </h3>
            <div>
              <p>{noOfCartItems}</p>

            </div>
          </div>
        </div>

        <div className="products">
          <div className="sticker">
            <div
              className="circle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                marginLeft: 30,
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <div>
                <div
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: 90,
                  }}
                >
                  <img src={circleColor1} width="150px" />
                </div>
              </div>
              <div className="sticker_1">
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "27px",
                    fontWeight: 500,
                    fontSize: "18px",
                    fontStyle: "normal",
                  }}
                >
                  Bump Tag
                </div>
                <div className="details">
                  The perfect Bump to use on phones and tablets
                </div>

                <div style={{ display: "flex", paddingTop: 20 }}>
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "white",
                      cursor: "pointer",
                      border:
                        circleColor1 === sticker_white
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      setCircleColor1(sticker_white);
                      setColor("white");
                    }}
                  ></div>
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "black",
                      marginLeft: 20,
                      border:
                        circleColor1 === stickerb
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCircleColor1(stickerb);
                      setColor("black");
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 10,
                  }}
                >
                  Price : Rs.499
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 5,
                  }}
                >
                  Enter Quantity
                </div>
                <div style={{ paddingTop: 10 }}>
                  <input
                    style={{
                      width: "50px",
                      borderRadius: "4px",
                      height: "24px",
                      borderColor: "gray",
                            borderWidth: 0.1
                    }}
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="1"
                    value={qty1}
                    onChange={(e) => setQty1(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="buttons"
                    onClick={() => {
                      setOpenDialog(true);
                      setType("sticker");
                      setQty(qty1);
                    }}
                  >
                    <h3 className="button-text">Place Order</h3>
                  </div>
                  <div
                    className="buttons2"
                    onClick={() => {
                      alert("Added to cart");
                      a.push("sticker");
                      localStorage.setItem("cartItems", a);
                      setNoOfCartItems(a.length)
                    }}
                  >
                    <h3 className="button-text2">Add To Cart</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="rectangle">
              <div
                style={{
                  borderRadius: "8px",
                  marginLeft: 40,

                }}
              >
                <img
                  src={rectangle1} className="img" />
              </div>
              <div style={{ marginLeft: 30 }}>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "27px",
                    fontWeight: 500,
                    fontSize: "18px",
                    fontStyle: "normal",
                  }}
                >
                  Bump Card
                </div>
                <div className="details1">
                  Your handy partner that you can carry around in a wallet
                </div>
                <div style={{ display: "flex", paddingTop: 20 }}>
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "white",
                      cursor: "pointer",
                      border:
                        rectangle1 === normalWhite
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      setRectangle1(normalWhite);
                      setColor("white");
                    }}
                  ></div>
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "black",
                      cursor: "pointer",
                      marginLeft: 20,
                      border:
                        rectangle1 === normalBlack
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      setRectangle1(normalBlack);
                      setColor("black");
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 10,
                  }}
                >
                  Price : Rs.549
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 5,
                  }}
                >
                  Enter Quantity
                </div>
                <div style={{ paddingTop: 10 }}>
                  <input
                    style={{
                      width: "50px",
                      borderRadius: "4px",
                      height: "24px",
                      borderColor: "gray",
                            borderWidth: 0.1
                    }}
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="1"
                    value={qty2}
                    onChange={(e) => setQty2(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="buttons"
                    onClick={() => {
                      setOpenDialog(true);
                      setType("card");
                      setQty(qty2);
                    }}
                  >
                    <h3 className="button-text">Place Order</h3>
                  </div>
                  <div
                    className="buttons2"
                    onClick={() => {
                      alert("Added to cart");
                      a.push("card");
                      localStorage.setItem("cartItems", a);
                      setNoOfCartItems(a.length)
                    }}
                  >
                    <h3 className="button-text2">Add To Cart</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row2">
          <div className="card">
            <div className="rectangle">
              <div className="combo_imagediv">
                <img src={comboImage} className="combo_img" />
              </div>
              <div className="combo_details">
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "27px",
                    fontWeight: 500,
                    fontSize: "18px",
                    fontStyle: "normal",
                  }}
                >
                  Bump Combo
                </div>
                <div className="details">
                  Your handy partner that you can carry around in a wallet
                </div>
                <div style={{ display: "flex", margin: 0 }}>
                  <div style={{}}>
                    <div className="details_sticker">Sticker</div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          backgroundColor: "white",
                          cursor: "pointer",
                          border:
                            color1 === "white"
                              ? "2px solid blue"
                              : "2px solid black",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          // setRectangle1(normalWhite);
                          setColor1("white");
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          backgroundColor: "black",
                          cursor: "pointer",
                          marginLeft: 20,
                          border:
                            color1 === "black"
                              ? "2px solid blue"
                              : "2px solid black",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          // setRectangle1(normalBlack);
                          setColor1("black");
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: -80,
                    }}
                  >
                    <div className="details_sticker">Card</div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          backgroundColor: "white",
                          cursor: "pointer",
                          border:
                            color2 === "white"
                              ? "2px solid blue"
                              : "2px solid black",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          // setRectangle1(normalWhite);
                          setColor2("white");
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          backgroundColor: "black",
                          cursor: "pointer",
                          marginLeft: 20,
                          border:
                            color2 === "black"
                              ? "2px solid blue"
                              : "2px solid black",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          // setRectangle1(normalBlack);
                          setColor2("black");
                        }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 10,
                  }}
                >
                  Price : Rs.649
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 5,
                  }}
                >
                  Enter Quantity
                </div>
                <div style={{ paddingTop: 10 }}>
                  <input
                    style={{
                      width: "50px",
                      borderRadius: "4px",
                      height: "24px",
                      borderColor: "gray",
                            borderWidth: 0.1
                    }}
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="1"
                    value={qty3}
                    onChange={(e) => setQty3(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="buttons"
                    onClick={() => {
                      setColor(`sticker:${color1} card:${color2}`);
                      setOpenDialog(true);
                      setType("combo");
                      setQty(qty3);
                    }}
                  >
                    <h3 className="button-text">Place Order</h3>
                  </div>
                  <div
                    className="buttons2"
                    onClick={() => {
                      alert("Added to cart");
                      a.push("combo");
                      localStorage.setItem("cartItems", a);
                      setNoOfCartItems(a.length)
                    }}
                  >
                    <h3 className="button-text2">Add To Cart</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="rectangle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                marginTop: 20,
              }}
            >
              <div className="customImage">
                <Carousel
                  autoPlay={true}
                  responsive={responsive}>
                  <div style={{}}>
                    <img src={rectangle2} className="img" />
                  </div>

                  <div style={{}}>
                    <img src={rectangle3} className="img" />
                  </div>

                  <div style={{}}>
                    <img src={rectangle4} className="img" />
                  </div>
                </Carousel>
              </div>
              <div style={{ marginLeft: 30 }}>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "27px",
                    fontWeight: 500,
                    fontSize: "18px",
                    fontStyle: "normal",
                  }}
                >
                  Custom Bump
                </div>
                <div className="details">
                  Personalise your Bump by adding custom branding
                </div>
                <div style={{ display: "flex", paddingTop: 20 }}>
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "white",
                      cursor: "pointer",
                      border:
                        rectangle2 === custom1w
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      setRectangle2(custom1w);
                      setRectangle3(custom2w);
                      setRectangle4(custom3w);
                      setColor("white");
                    }}
                  ></div>
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "black",
                      cursor: "pointer",
                      marginLeft: 20,
                      border:
                        rectangle2 === custom1b
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      setRectangle2(custom1b);
                      setRectangle3(custom2b);
                      setRectangle4(custom3b);
                      setColor("black");
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 10,
                  }}
                >
                  Price : Rs.699
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    lineHeight: "18px",
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontStyle: "normal",
                    color: "#8E8E8E",
                    width: "171px",
                    paddingTop: 5,
                  }}
                >
                  Enter Quantity
                </div>
                <div style={{ paddingTop: 10 }}>
                  <input
                    style={{
                      width: "50px",
                      borderRadius: "4px",
                      height: "24px",
                      borderColor: "gray",
                            borderWidth: 0.1
                    }}
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="1"
                    value={qty4}
                    onChange={(e) => setQty4(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="buttons"
                    onClick={() => {
                      setOpenDialog(true);
                      setType("custom");
                      setIsCustom(true);
                      setQty(qty4);
                    }}
                  >
                    <h3 className="button-text">Place Order</h3>
                  </div>
                  <div
                    className="buttons2"
                    onClick={() => {
                      alert("Added to cart");
                      a.push("custom");
                      localStorage.setItem("cartItems", a);
                      setNoOfCartItems(a.length)
                    }}
                  >
                    <h3 className="button-text2">Add To Cart</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Bump />
      </div>
      <Dialog
        open={openDialog}
        // TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        onClose={handleCloseDialog}
      >
        <DialogContent
          style={{ backgroundColor: "#edf3fc" }}
        >
          <div className="modal">
            <div className="inner_modal">
              <h1
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  margin: 0,
                  marginTop: 10,
                }}
              >
                Name
              </h1>
              <input
                style={{
                  width: "261px",
                  borderRadius: "4px",
                  height: "24px",
                  border: "1px solid black",
                  borderColor: '#EDF3FC',
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <h1
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  margin: 0,
                  marginTop: 10,
                }}
              >
                Email
              </h1>
              <input
                style={{
                  width: "261px",
                  borderRadius: "4px",
                  height: "24px",
                  border: "1px solid black",
                  borderColor: '#EDF3FC',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <h1
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  margin: 0,
                  marginTop: 10,
                }}
              >
                Phone Number
              </h1>
              <input
                type="number"
                style={{
                  width: "261px",
                  borderRadius: "4px",
                  height: "24px",
                  border: "1px solid black",
                  borderColor: '#EDF3FC',
                }}
                value={phno}
                onChange={(e) => setPhno(e.target.value)}
              />
              <h1
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  margin: 0,
                  marginTop: 10,
                }}
              >
                Address
              </h1>
              <textarea
                placeholder="Type your address"
                style={{
                  width: "261px",
                  borderRadius: "4px",
                  height: "80px",
                  borderColor: '#EDF3FC',
                }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              {isCustom && (
                <div>
                  <h1
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "24px",
                      margin: 0,
                    }}
                  >
                    Custom Name
                  </h1>
                  <input
                    style={{
                      width: "261px",
                      borderRadius: "4px",
                      height: "24px",
                      border: "1px solid black",
                      borderColor: '#EDF3FC',
                    }}
                    value={customName}
                    onChange={(e) => setCustomName(e.target.value)}
                  />
                </div>
              )}

              <div
                style={{
                  width: "261px",
                  height: "39px",
                  backgroundColor: "#4784E1",
                  borderRadius: "50px",
                  marginTop: 20,
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => submitHandler(e)}
              >
                <h1
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                    paddingTop: 8,
                  }}
                >
                  Submit
                </h1>
              </div>
            </div>
            <div
              onClick={handleCloseDialog}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 0,
                top: 0,
                marginTop: 20,
                marginRight: 20,
              }}
            >
              <img src={cross} width="16px" />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDialog1}
        // TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        onClose={handleCloseDialog1}
      >
        <DialogContent>
          <div
            style={{
              minHeight: 400,
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "30px",
                lineHeight: "24px",
              }}
            >
              Cart
            </div>
            <Cart />
            <div
              onClick={handleCloseDialog1}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 0,
                top: 0,
                marginTop: 20,
                marginRight: 30,
              }}
            >
              <img src={cross} width="16px" />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Products1;
