import React from 'react';
import './profileOf.css'
import OffImg from './images/Off.png'
function ProfileOf() {
    return <div className='profile-off' >
        <img src={OffImg} />
        <p>The user profile you are trying to connect with is currently OFF. You can connect only when the profile is ON</p>
    </div>;
}

export default ProfileOf;
