import React, { useEffect, useRef, useState } from 'react'
import firebase from "../../../firebase";
import './components.css'
import ProfDefault from '../../../assets/images/account.png'
import { Switch } from '@material-ui/core';

const PhotoWrapper = ({ user, setUser }) => {

    console.log(user, 'user')

    const ref = useRef(null)

    const [profImg, setProfImg] = useState(undefined)

    const getImage = async () => {


        try {
            let imageRef = user.username + '.' + user.profImgExtension;
            // const url = await firebase.storage().ref(imageRef).getDownloadURL();
            var storageRef = firebase.storage().ref();

            // Create a reference to 'mountains.jpg'
            var fileref = storageRef.child(imageRef);

            let url = await fileref.getDownloadURL()

            // console.log(url)

            setProfImg(url)

        } catch (error) {
            console.log(error)
        }

    }

    const profPicUpload = async (e) => {

        // console.log(e.target.files)

        const file = e.target.files[0] ?? undefined

        // console.log(file)
        setProfImg(URL.createObjectURL(file))
        if (!file) return
        let extension = file.type.split('/')[1]
        let filename = user.username + '.' + extension;

        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Create a reference to 'mountains.jpg'
        var fileref = storageRef.child(filename);

        await fileref.put(file)

        // await firebase.storage().ref(filename).putFile(file);

        await firebase.firestore()
            .collection('Users')
            .doc(user.id)
            .update({
                profImgExtension: extension,
            })
            .then(() => {
                console.log('Photo updated!');
                // setLoading(false);
            })
            .catch(error => {
                // setLoading(false);
                console.log(error);
            });
    };


    useEffect(() => {
        getImage()
    }, [user])
    return (
        <div className='photo_wrpr' >
            <div style={{ display: 'flex', alignItems: 'center' }} >
                <p className='on_of_label' >Profile Off</p>
                <Switch onChange={e => { user.profStatus = e.target.checked; setUser({ ...user }) }} checked={user ? user.profStatus : true} />
                <p className='on_of_label'>Profile On</p>
            </div>
            <input type='file' hidden ref={ref} onChange={profPicUpload} />
            <div className='prof_container' onClick={() => ref?.current?.click()} >
                <img className='prof_pic' style={{ width: profImg ? '100%' : 25, height: profImg ? '100%' : 25, }} src={profImg ?? ProfDefault} />

            </div>
            <p style={{ marginLeft: 10 }} >Upload Profile Picture</p>
        </div>
    )
}

export default PhotoWrapper