import React from 'react'
import './activate.css'
import Image from './images/bump_phone.png'
function Activate() {
    return (
        <div className='activate' >
            <div
                className='text_wrpr'
            >
                <h1 className="intro-t2">Activate your bump</h1>

                <div>
                    <h3>Setup is very easy!</h3>
                    <p>
                        When your bump arrives, <br />
                        download the app from <br />
                        Playstore/app store and sign in.
                    </p>
                </div>

            </div>
            <div className='img_wrpr'>
                <img src={Image}  className='img' />
            </div>
        </div>
    )
}

export default Activate
