import React, { useEffect, useState } from "react";
import Header from "../../components/Home/Header";
import bump from "./images/bump.png";
import custom from "./images/custom.png";
import normalWhite from "./images/normalWhite.png";
import normalBlack from "./images/normalBlack.png";
import stickerb from "./images/stickerb.png";
import custom1b from "./images/custom1b.png";
import custom1w from "./images/custom1w.png";
import custom2w from "./images/custom2w.png";
import custom2b from "./images/custom2b.png";
import custom3b from "./images/custom3b.png";
import combo from "./images/combo.png";
import Carousel from "react-multi-carousel";
import custom3w from "./images/custom3w.png";
import axios from "axios";
import "./Cart.css";
import cross from "../../assets/images/cross.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Cart = () => {
  const history = useHistory();

  const [qty1, setQty1] = useState(1);
  const [qty2, setQty2] = useState(1);
  const [qty3, setQty3] = useState(1);
  const [qty4, setQty4] = useState(1);
  const [qty, setQty] = useState(1);
  const [circleColor1, setCircleColor1] = useState("white");
  const [rectangle1, setRectangle1] = useState(normalWhite);
  const [rectangle2, setRectangle2] = useState(custom1w);
  const [rectangle3, setRectangle3] = useState(custom2w);
  const [rectangle4, setRectangle4] = useState(custom3w);
  const [isCustom, setIsCustom] = useState(false);
  const [isSticker, setIsSticker] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const [isCombo, setIsCombo] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phno, setPhno] = useState("");
  const [address, setAddress] = useState("");
  const [customName, setCustomName] = useState("");
  const [cartItems, setCartItems] = useState([])

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setName("");
    setEmail("");
    setPhno("");
    setAddress("");
    setCustomName("");
    setIsCustom(false);
  };

  var array = [];
  var uniqueChars;
  if (localStorage.getItem("cartItems") != null) {
    const data = localStorage.getItem("cartItems");
    array = data.split(",");
  }

  uniqueChars = array.filter((c, index) => {
    return array.indexOf(c) === index;
  });

  useEffect(() => {
    var array = [];
    var uniqueChars;
    if (localStorage.getItem("cartItems") != null) {
      const data = localStorage.getItem("cartItems");
      array = data.split(",");
    }

    uniqueChars = array.filter((c, index) => {
      return array.indexOf(c) === index;
    });
    setCartItems(uniqueChars)

  })

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    uniqueChars?.map(async (tag) => {
      let color;
      let quantity;

      if (tag == "sticker") {
        color = circleColor1;
        quantity = qty1;
      }
      if (tag == "card") {
        color = rectangle1;
        quantity = qty2;
      }
      if (tag == "combo") {
        color = rectangle1;
        quantity = setQty3;
      }
      if (tag == "custom") {
        color = rectangle1;
        quantity = setQty4;
      }

      const data = {
        name: name,
        email: email,
        phno: phno,
        address: address,
        type: tag,
        color: color,
        customName: customName,
        qty: qty,
        // data: cartdata
      };

      // let formdata = new FormData()
      // formdata.append('name', name)
      // formdata.append('email', email)
      // formdata.append('phoneNumber', phno)
      // formdata.append('address', address)
      // formdata.append('customName', customName)
      // formdata.append('data', data)

      await axios.post(
        "https://sheet.best/api/sheets/f0ea51ff-fd54-4e9c-a22c-1a6096ec878f",
        data
      );
    });

    // .then((response) => {
    //   console.log(response, 'eeeee')
    //   console.log('here')
    //   if (response.data.result == 'success') {
    //     history.push('payments/do')
    //   } else {
    //     alert('please try again')
    //   }
    // })
  };

  const deleteCartHandler = (item) => {

    let data = localStorage.getItem("cartItems");
    array = data.split(",");
    uniqueChars = array.filter((c, index) => {
      return array.indexOf(c) === index;
    })
    const index = uniqueChars.indexOf(item);
    if (index > -1) {
      uniqueChars.splice(index, 1);
    }
    localStorage.setItem("cartItems", uniqueChars)
    setCartItems(uniqueChars)

  }

  useEffect(() => {
    uniqueChars.map((a) => a == "custom" && setIsCustom(true));
  }, [uniqueChars]);

  return (
    <div>
      <div style={{}}>
        {localStorage.getItem("cartItems") == null || cartItems.length == 1 ? (
          <h4
            style={{
              fontFamily: "Poppins",
              lineHeight: "18px",
              fontWeight: "normal",
              fontSize: "25px",
              fontStyle: "normal",
              color: "#8E8E8E",
              textAlign: "center",
            }}
          >
            No Items In Cart
          </h4>
        ) : (
          cartItems.map((a) => (
            <div
              style={{
                marginTop: "20px",
              }}
            >
              {a == "sticker" ? (
                <div className="sticker1">
                  <div
                    className="circle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginLeft: 30,
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <div>
                      <div className="sticker_class">
                        <img src={stickerb} className="sticker_image" />
                      </div>
                    </div>
                    <div className="sticker_1">
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "27px",
                          fontWeight: 500,
                          fontSize: "18px",
                          fontStyle: "normal",
                        }}
                      >
                        Bump Tag
                      </div>
                      <div className="details">
                        The perfect Bump to use on phones and tablets
                      </div>

                      <div style={{ display: "flex", paddingTop: 20 }}>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "white",
                            cursor: "pointer",
                            border:
                              circleColor1 === "white"
                                ? "2px solid blue"
                                : "2px solid black",
                            borderRadius: "16px"
                          }}
                          onClick={() => {
                            setCircleColor1("white");
                            // setColor("white");
                          }}
                        ></div>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "black",
                            marginLeft: 20,
                            border:
                              circleColor1 === "black"
                                ? "2px solid blue"
                                : "2px solid black",
                            cursor: "pointer",
                            borderRadius: "16px"
                          }}
                          onClick={() => {
                            setCircleColor1("black");
                            // setColor("black");
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 20,
                        }}
                      >
                        Price : Rs.499
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 10,
                        }}
                      >
                        Enter Quantity
                      </div>
                      <div style={{ paddingTop: 10 }}>
                        <input
                          style={{
                            width: "50px",
                            borderRadius: "4px",
                            height: "24px",
                            borderColor: "gray",
                            borderWidth: 0.1
                          }}
                          type="number"
                          id="quantity"
                          name="quantity"
                          min="1"
                          value={qty1}
                          onChange={(e) => setQty1(e.target.value)}
                        />
                      </div>
                      <div
                        className="buttons2"
                        onClick={() => {
                          deleteCartHandler("sticker")
                          // alert("Added to cart");
                          // a.push("custom");
                          // localStorage.setItem("cartItems", a);
                          // setNoOfCartItems(a.length)
                        }}
                      >
                        <h3 className="button-text2">Remove</h3>
                      </div>
                      {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            marginTop: 10,

                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // setOpenDialog(true);
                            // setType("sticker");
                            // setQty(qty1);
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              marginTop: "6px",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "21px",
                              cursor: "pointer",
                              color: "#4784E1",
                            }}
                          >
                            Place Order {">"}
                          </h3>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : a == "card" ? (
                <div className="card1">
                  <div className="rectangle">
                    <div
                      style={{
                        boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.08)",
                        borderRadius: "8px",
                        marginLeft: 40,
                      }}
                    >
                      <img src={rectangle1} className="img1" />
                    </div>
                    <div style={{ marginLeft: 30 }}>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "27px",
                          fontWeight: 500,
                          fontSize: "18px",
                          fontStyle: "normal",
                        }}
                      >
                        Bump Card
                      </div>
                      <div className="details">
                        Your handy partner that you can carry around in a wallet
                      </div>
                      <div style={{ display: "flex", paddingTop: 20 }}>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "white",
                            cursor: "pointer",
                            border:
                              rectangle1 === normalWhite
                                ? "2px solid blue"
                                : "2px solid black",
                            borderRadius: "16px"
                          }}
                          onClick={() => {
                            setRectangle1(normalWhite);
                            // setColor("white");
                          }}
                        ></div>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "black",
                            cursor: "pointer",
                            marginLeft: 20,
                            border:
                              rectangle1 === normalBlack
                                ? "2px solid blue"
                                : "2px solid black",
                            borderRadius: "16px"
                          }}
                          onClick={() => {
                            setRectangle1(normalBlack);
                            // setColor("black");
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 20,
                        }}
                      >
                        Price : Rs.549
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 10,
                        }}
                      >
                        Enter Quantity
                      </div>

                      <div style={{ paddingTop: 10 }}>
                        <input
                          style={{
                            width: "50px",
                            borderRadius: "4px",
                            height: "24px",
                            borderColor: "gray",
                            borderWidth: 0.1
                          }}
                          type="number"
                          id="quantity"
                          name="quantity"
                          min="1"
                          value={qty2}
                          onChange={(e) => setQty2(e.target.value)}
                        />

                      </div>
                      <div
                        className="buttons2"
                        onClick={() => {
                          deleteCartHandler('card')
                          // alert("Added to cart");
                          // a.push("custom");
                          // localStorage.setItem("cartItems", a);
                          // setNoOfCartItems(a.length)
                        }}
                      >
                        <h3 className="button-text2">Remove</h3>
                      </div>
                      {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // setOpenDialog(true);
                            // setType("card");
                            // setQty(qty2);
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              marginTop: "6px",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "21px",
                              cursor: "pointer",
                              color: "#4784E1",
                            }}
                          >
                            Place Order {">"}
                          </h3>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : a == "combo" ? (
                <div className="card2">
                  <div className="rectangle">
                    <div
                      style={{
                        boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.08)",
                        borderRadius: "8px",
                        marginLeft: 30,
                      }}
                    >
                      <img src={combo} className="img2" />
                    </div>
                    <div className="combo_left">
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "27px",
                          fontWeight: 500,
                          fontSize: "18px",
                          fontStyle: "normal",
                        }}
                      >
                        Bump Combo
                      </div>
                      <div className="details">
                        Your handy partner that you can carry around in a wallet
                      </div>
                      <div style={{ display: "flex", paddingTop: 20 }}>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "white",
                            cursor: "pointer",
                            border:
                              rectangle1 === normalWhite
                                ? "2px solid blue"
                                : "2px solid black",
                            borderRadius: "16px"

                          }}
                          onClick={() => {
                            setRectangle1(normalWhite);
                            // setColor("white");
                          }}
                        ></div>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "black",
                            cursor: "pointer",
                            marginLeft: 20,
                            border:
                              rectangle1 === normalBlack
                                ? "2px solid blue"
                                : "2px solid black",
                            borderRadius: "16px"
                          }}
                          onClick={() => {
                            setRectangle1(normalBlack);
                            // setColor("black");
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 20,
                        }}
                      >
                        Price : Rs.649
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 10,
                        }}
                      >
                        Enter Quantity
                      </div>
                      <div style={{ paddingTop: 10 }}>
                        <input
                          style={{
                            width: "50px",
                            borderRadius: "4px",
                            height: "24px",
                            borderColor: "gray",
                            borderWidth: 0.1
                          }}
                          type="number"
                          id="quantity"
                          name="quantity"
                          min="1"
                          value={qty3}
                          onChange={(e) => setQty3(e.target.value)}
                        />
                      </div>
                      <div
                        className="buttons2"
                        onClick={() => {
                          deleteCartHandler('combo')
                          // alert("Added to cart");
                          // a.push("custom");
                          // localStorage.setItem("cartItems", a);
                          // setNoOfCartItems(a.length)
                        }}
                      >
                        <h3 className="button-text2">Remove</h3>
                      </div>
                      {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // setOpenDialog(true);
                            // setType("combo");
                            // setQty(qty3);
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              marginTop: "6px",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "21px",
                              cursor: "pointer",
                              color: "#4784E1",
                            }}
                          >
                            Place Order {">"}
                          </h3>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : a == "custom" ? (
                <div className="card3">
                  <div
                    className="rectangle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginTop: 20,
                    }}
                  >
                    <div className="customImage1">
                      <Carousel responsive={responsive}>
                        <div style={{}}>
                          <img src={rectangle2} className="img3" />
                        </div>

                        <div style={{}}>
                          <img src={rectangle3} className="img3" />
                        </div>

                        <div style={{}}>
                          <img src={rectangle4} className="img3" />
                        </div>
                      </Carousel>
                    </div>
                    <div style={{ marginLeft: 30 }}>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "27px",
                          fontWeight: 500,
                          fontSize: "18px",
                          fontStyle: "normal",
                        }}
                      >
                        Custom Bump
                      </div>
                      <div className="details">
                        Personalise your Bump by adding custom branding
                      </div>
                      <div style={{ display: "flex", paddingTop: 20 }}>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "white",
                            cursor: "pointer",
                            border:
                              rectangle2 === custom1w
                                ? "2px solid blue"
                                : "2px solid black",
                            borderRadius: "16px"
                          }}
                          onClick={() => {
                            setRectangle2(custom1w);
                            setRectangle3(custom2w);
                            setRectangle4(custom3w);
                            // setColor("white");
                          }}
                        ></div>
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "black",
                            cursor: "pointer",
                            marginLeft: 20,
                            border:
                              rectangle2 === custom1b
                                ? "2px solid blue"
                                : "2px solid black",
                            borderRadius: "16px"
                          }}
                          onClick={() => {
                            setRectangle2(custom1b);
                            setRectangle3(custom2b);
                            setRectangle4(custom3b);
                            // setColor("black");
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 20,
                        }}
                      >
                        Price : Rs.699
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: "18px",
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontStyle: "normal",
                          color: "#8E8E8E",
                          width: "171px",
                          paddingTop: 10,
                        }}
                      >
                        Enter Quantity
                      </div>
                      <div style={{ paddingTop: 10 }}>
                        <input
                          style={{
                            width: "50px",
                            borderRadius: "4px",
                            height: "24px",
                            borderColor: "gray",
                            borderWidth: 0.1
                          }}
                          type="number"
                          id="quantity"
                          name="quantity"
                          min="1"
                          value={qty4}
                          onChange={(e) => setQty4(e.target.value)}
                        />
                      </div>
                      <div
                        className="buttons2"
                        onClick={() => {
                          deleteCartHandler('custom')
                          // alert("Added to cart");
                          // a.push("custom");
                          // localStorage.setItem("cartItems", a);
                          // setNoOfCartItems(a.length)
                        }}
                      >
                        <h3 className="button-text2">Remove</h3>
                      </div>
                      {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // setOpenDialog(true);
                            // setType("custom");
                            // setIsCustom(true);
                            // setQty(qty4);
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              marginTop: "6px",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "21px",
                              cursor: "pointer",
                              color: "#4784E1",
                            }}
                          >
                            Place Order {">"}
                          </h3>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))
        )}
        {localStorage.getItem("cartItems") != null && (
          <div
            style={{
              width: 130,
              backgroundColor: "black",
              marginTop: 10,
              borderRadius: 24,
              textAlign: "center",
              cursor: "pointer",
              margin: "0 auto",
            }}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <h3
              style={{
                fontFamily: "Poppins",
                marginTop: "6px",
                fontWeight: 600,
                fontSize: "18px",
                color: "white",
                cursor: "pointer",
              }}
            >
              Buy All
            </h3>
          </div>
        )}
      </div>
      <Dialog
        open={openDialog}
        // TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <div
            style={{
              marginLeft: -10,
            }}
          >
            <h1
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Name
            </h1>
            <input
              style={{
                width: "261px",
                borderRadius: "4px",
                height: "24px",

              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <h1
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Email
            </h1>
            <input
              style={{
                width: "261px",
                borderRadius: "4px",
                height: "24px",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <h1
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Phone Number
            </h1>
            <input
              type="number"
              style={{
                width: "261px",
                borderRadius: "4px",
                height: "24px",
              }}
              value={phno}
              onChange={(e) => setPhno(e.target.value)}
            />
            <h1
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                margin: 0,
                marginTop: 10,
              }}
            >
              Address
            </h1>
            <textarea
              placeholder="Type your address"
              style={{
                width: "261px",
                borderRadius: "4px",
                height: "80px",
              }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {isCustom && (
              <div>
                <h1
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "24px",
                    margin: 0,
                  }}
                >
                  Custom Name
                </h1>
                <input
                  style={{
                    width: "261px",
                    borderRadius: "4px",
                    height: "24px",
                  }}
                  value={customName}
                  onChange={(e) => setCustomName(e.target.value)}
                />
              </div>
            )}

            <div
              style={{
                width: "261px",
                height: "39px",
                backgroundColor: "#4784E1",
                borderRadius: "50px",
                marginTop: 10,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={(e) => submitHandler(e)}
            >
              <h1
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  paddingTop: 8,
                }}
              >
                Submit
              </h1>
            </div>
          </div>
          <div
            onClick={handleCloseDialog}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 0,
              top: 0,
              marginTop: 20,
              marginRight: 20,
            }}
          >
            <img src={cross} width="16px" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Cart;
