import React from "react";
import "./Middle.css";
const Middle = ({ productsRef }) => {
  return (
    <>
      <div
        className='middleContainer'

      >
        <h1>Change your way of </h1>
        <h1>connecting with people</h1>
      </div>
      <div

        style={{
          textAlign: "center",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "12px",
          marginTop: 55,
          color: "white",
        }}
      >
        <h1
          className='subtitle'
        >One-tap solution for all your business and personal networking</h1>
      </div>
      <div
        className="explore"
      style={{
          alignItems: "center",
          marginTop: 60,
        }}
        onClick={() => productsRef.current.scrollIntoView({ behavior: 'smooth' })}
      >
        <h1>Explore</h1>
      </div>
    </>
  );
};

export default Middle;
