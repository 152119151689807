import React from 'react'
import { useHistory } from 'react-router-dom'
import BackIco from '../../assets/images/back.png'

const Navigation = ({ label }) => {

    const history = useHistory()

    return (
        <div style={{ paddingLeft: 20, display: 'flex' }} >
            <img
                onClick={() => history.goBack()}
                src={BackIco}
                style={{ width: 25, height: 25, marginTop: 20, marginRight: 10, cursor: 'pointer' }}
            />
            <p style={{ color: '#383838', fontSize: 20 }} >{label}</p>

        </div>
    )
}

export default Navigation