import axios from 'axios'
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useHistory, useLocation } from 'react-router-dom'
import NewBtn from '../../components/btn/NewBtn'
import { auth } from '../../firebase'
import AuthHeader from './components/AuthHeader'
import firebase from "../../firebase";

const OTPScreen = () => {
    const location = useLocation()
    const history = useHistory()

    const { email, password } = location?.state ?? {};

    const [otp, setOtp] = useState('')
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false)


    const sendMail = async () => {


        // console.log(email)
        // sendEmail(otp, email)
        try {
            const response = await axios.post(` https://us-central1-sample-7be24.cloudfunctions.net/app/sendOtp`, {
                // const response = await axios.post(`http://localhost:5000/sample-7be24/us-central1/app/sendOtp`, {
                email,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200) {
                // console.log(response.data)
                setOtp(response.data)

                alert('Code has been send to your email')
                setLoading(false);

            } else {
                console.log(response.data)
                throw new Error("An error has occurred");
            }
        } catch (error) {
            // console.log(error);
            // console.log('here')
            setLoading(false);
        }
    }


    const registerEmail = async () => {

        await auth.createUserWithEmailAndPassword(email, password)
            .then(() => {
                console.log('User account created & signed in!');
               
            })
            .catch(error => {



                console.error(error);
            });

    };

    const verificationHandler = async () => {

        if (otp == code) {
            await registerEmail()
            await firebase.firestore()
                .collection('Users')
                .add({
                    email,
                    verified: true
                })
                .then(() => {
                    console.log('code correct');
                    history.push('/create_username')
                    setLoading(false)
                });
        } else {
            alert(
                'Incorrect OTP',
                'Please try again',
            );
        }
    };

    useEffect(() => {
        sendMail()

    }, [])
    return (
        <div className='otp_page' >
            <AuthHeader label='Verify Email ID' />
            <sub className='sub' >
                <p className='info' >Enter the verification code sent to your email ID</p>
                <div style={{ marginTop: 50 }} >
                    <OtpInput
                        value={code}
                        onChange={(e) => setCode(e)}
                        numInputs={6}
                        separator={<span style={{ width: 20 }} > </span>}
                        inputStyle={{ width: 30, height: 30, backgroundColor: '#4784E133', border: 'none', borderRadius: 5 }}
                    />
                </div>
                <p className='resend' >Resend verification email</p>
                <NewBtn label='Continue' disabled={!otp.length == '6'} onClick={verificationHandler} />
            </sub>
        </div>
    )
}

export default OTPScreen