import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Navigation from '../../components/Header/Navigation'
import { auth } from '../../firebase'
import './account.css'
import firebase from "../../firebase";
import AccDetails from './components/AccDetails'
import SocialBtnComponent from '../../components/socialBtnComponent/SocialBtnComponent'
import Modal from 'react-modal';
import EditSocialMediaModal from './components/EditSocialMediaModal'


const Account = () => {

    const history = useHistory()

    const userAuth = auth.currentUser


    const [showMediaModal, setShowMediaModal] = useState(false);
    const [user, setUser] = useState('')


    const socialMediaComps = [
        { title: 'Instagram', key: 'one' },
        { title: 'Facebook', key: 'two' },
        { title: 'Whatsapp', key: 'three' },
        { title: 'Youtube', key: 'four' },
        { title: 'SnapChat', key: 'five' },
        { title: 'Twitter', key: 'six' },
        { title: 'Linkedin', key: 'seven' },
        { title: 'Pinterest', key: 'eight' },
        { title: 'Spotify', key: 'fifteen' },
        { title: 'Moj', key: 'Sixteen' },
        { title: 'Quora', key: 'Seventeen' },
        { title: 'MX TakaTak', key: 'Eighteen' },
        { title: 'Roposo', key: 'Nineteen' },
        { title: 'Josh', key: 'Twenty' },
        { title: 'ClubHouse', key: 'Twentyone' },
        { title: 'Discord', key: 'Twentytwo' },
        { title: 'Telegram', key: 'Twentythree' },
    ];
    const contactDetails = [
        { title: 'Call', key: 'nine' },
        { title: 'Contact', key: 'ten' },
        { title: 'Text', key: 'eleven' },
        { title: 'Email', key: 'twelve' },
        { title: 'Google Maps', key: 'thirteen' },
        { title: 'Custom Url', key: 'fourteen' },
    ];

    const getAccountDetails = async () => {
        try {
            firebase
                .firestore()
                .collection("Users")
                .where("email", "==", userAuth.email)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        console.log(doc.data())
                        setUser({ id: doc.id, ...doc.data() })
                       

                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })
                .then(() => {
                    // getProfPic(username, tempExtension);
                });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!userAuth) {
            history.push('/user_login')
        } else {
            if (!showMediaModal) {
                getAccountDetails()

            }
        }
    }, [userAuth, showMediaModal])

    return (
        <div className='account' >
            <Navigation />
            <div className='sub' >
                <AccDetails user={user} />

                {/* <h3 className='subTitle' >Social Media</h3> */}
                <div className="socialContainer">
                    {
                        user.socialMedia?.map((item) => (
                            <SocialBtnComponent setShowMediaModal={setShowMediaModal} from='ACCOUNT' socialMedia={user.socialMedia} item={item} publicUsername={user.publicUsername} />
                        ))
                    }


                </div>
                {/* <h3 className='subTitle'>Contacts</h3>
                <div className="socialContainer">
                    {
                        contactDetails.map((item) => (
                            <SocialBtnComponent setShowMediaModal={setShowMediaModal} from='ACCOUNT' item={item}  socialMedia={user.socialMedia}publicUsername={user.publicUsername} />
                        ))
                    }


                </div> */}
            </div>


            <Modal
                isOpen={showMediaModal}
                // onAfterOpen={()}
                onRequestClose={() => setShowMediaModal(null)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                    overlay: {
                        backgroundColor: '#A7A7A790'
                    }
                }}
                contentLabel="Example Modal"
            >
                <EditSocialMediaModal user={user} media={showMediaModal} setShowMediaModal={setShowMediaModal} />
            </Modal>
        </div>
    )
}

export default Account