import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import firebase from "../../../firebase";
import SettingsIco from '../../../assets/images/settings.png'
import { useHistory } from 'react-router-dom';

const AccDetails = ({ user }) => {

    const history = useHistory()
    const [profImg, setProfImg] = useState('')


    const getImage = async () => {

      try {
        let imageRef = user.username + '.' + user.profImgExtension;
        const url = await firebase.storage().ref(imageRef).getDownloadURL();
        console.log(url)
        setProfImg(url)
      } catch (error) {
        
      }

    }


    useEffect(() => {
        getImage()
    }, [user])

    return (
        <div style={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
            <img src={profImg} style={{ width: 150, height: 150, borderRadius: 500 }} />
            <div style={{ display: 'flex' }} >
                <p style={{ fontSize: 25, fontWeight: '700' }} >{user.username}</p>
                <img
                    src={SettingsIco}
                    style={{ width: 25, height: 25, marginLeft: 15, cursor: 'pointer' }}
                    onClick={() => history.push('/settings')}
                />
            </div>
            <p style={{ color: 'gray' }} >{user.designation}</p>
            <p style={{ color: 'gray' }}>https://bumpme.in/{user.username}</p>
        </div>
    )
}

export default AccDetails