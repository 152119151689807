import FileSaver from "file-saver";
import VCard from "vcard-creator";
import { Base64 } from 'js-base64';


export const LinkHandler = (item, publicUsername) => {
    // useEffect(()=>{
    let options = {
        location: "yes",
        height: 520,
        width: 570,
        scrollbars: "yes",
        status: "yes"

    }
    const vCardCreater = () => {
        const myVCard = new VCard()
        let name = item.link

        if (item.lastName) {  // inorder to link the last name in staff data
            name = name + item.lastName
        }
        myVCard.addName(name)
        myVCard.addPhoneNumber(item.contactPhNo)
        myVCard.addEmail(item.contactEmail)
        myVCard.addURL(item.url ?? '')
        myVCard.addCompany(item.company ?? '')
        myVCard.addJobtitle(item.jobTitle ?? '')


        const blob = new Blob([myVCard.toString()], { type: "text/vcard;charset=utf-8" });
        FileSaver.saveAs(blob, `${publicUsername}.vcf`);
    }

    return new Promise((resolve, reject) => {
        if (item.title === 'Facebook' || item.title == 'Youtube') {
            try {
                window.open(item.link, "_blank")
            } catch (error) {
                console.log(error)
            }

        }

        else if (item.title === 'Call') {
            try {
                window.open(`tel:${item.countryCode + item.link}`, "_self", 'location=yes,height=570,width=520,scrollbars=yes,status=yes, popup=yes')
            } catch (error) {
                console.log(error)
            }

        }
        else if (item.title === 'Email') {
            try {
                window.open(`mailto:${item.link}`)
            } catch (error) {
                console.log(error)
            }

        }
        else if (item.title === 'Whatsapp') {
            try {
                window.open(`https://wa.me/${item.countryCode + item.link}`)
            } catch (error) {
                console.log(error)
            }

        }
        else if (item.title === 'Contact') {
            try {
                vCardCreater()
            } catch (error) {
                console.log(error)
            }

        }
        else if (item.title === 'Custom Url' || item.title === 'Google Maps' || item.title == 'Linkedin' || item.title == 'Spotify'
            || item.title === 'Quora' || item.title == 'Roposo' || item.title == 'Pinterest'
        ) {

            let newLink = item.link.split("http").pop();
            try {
                window.open("http" + newLink, "_blank")
            } catch (error) {
                console.log(error)
            }

        }
        else if (item.title === 'Text') {
            try {
                window.open(`sms:${item.link}`, '_self')
            } catch (error) {
                console.log(error)
            }

        }
        else if (item.title === 'SnapChat') {
            try {
                window.open(`https://www.snapchat.com/add/${item.link}`);
            } catch (error) {
                console.log(error)
            }

        }


        else if (item.title == 'PhonePe' || item.title == 'GPay' || item.title == 'Paytm' || item.title == 'BharatPay' || item.title == 'AmazonPay') {
            try {
                var encode = Base64.decode(item.link)
                if (encode.length > 25) {
                    window.open(`${encode}`);
                }
                else {

                    window.open(`upi://pay?pa=${encode}&pn=PaytmUser&mc=0000&mode=02&purpose=00`);
                }

            } catch (error) {
                console.log(error)
            }

        }

        else {

            window.open(`https://www.${item.title.toLowerCase()}.com/${item.link}`, "_blank")
        }
        resolve()
    })
    // }, [])
};