import React from 'react'
import { useState } from 'react'

const UserNameWrpr = ({ user, setUser }) => {



    return (
        <div className='name_wrpr'
            style={{ display: 'flex', flexDirection: 'column', maxWidth: 200 }}
        >
            <p style={{ fontSize: 12 }} >Public Username</p>
            <input
                style={{ marginBottom: 10 }}
                className='input'
                placeholder='Enter Public Username'
                onChange={(e) => { user.publicUsername = e.target.value; setUser({ ...user }) }}
                value={user.publicUsername}
            />
            <input
                className='input'
                placeholder='Enter Designation'
                onChange={(e) => { user.designation = e.target.value; setUser({ ...user }) }}
                value={user.designation}
            />
            <p className='link' >www.bump.co/{user?.username}</p>
        </div>
    )
}

export default UserNameWrpr