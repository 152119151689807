import React from 'react'

const Btn = ({ label, onClick, containerStyle }) => {
    return (
        <div
            onClick={onClick}
            style={{
                padding: '10px 20px',
                backgroundColor: 'blue',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 5,
                cursor: 'pointer',
                ...containerStyle

            }}>
            <p
                style={{ margin: 0, padding: 0 }}
            >{label}</p>
        </div>
    )
}

export default Btn