import React, { useContext, useEffect } from 'react'
import Btn from '../../components/btn/Btn'
import './auth.css'
import LogoImg from '../../assets/images/logo.png'
import { useState } from 'react'
import { auth } from '../../firebase'
import { useHistory } from 'react-router-dom'
import AuthHeader from './components/AuthHeader'
import NewBtn from '../../components/btn/NewBtn'
import { AppContext } from '../../contexts/AppContext'



const SignUp = () => {

    const history = useHistory()

    const { setAdmin } = useContext(AppContext)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(true);
    const [toggleCheckBox, setToggleCheckBox] = useState(false);
    const [isOk, setIsOk] = useState(false);
    const [pwdStrength, setPwdStrength] = useState({
        status: 'weak',
        color: '#FF5252',
    });
    const [loading, setLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(true);


    const signupHandler = async () => {
        if (!email || !password) return alert('Enter Email and Password.')
        if (!validEmail)
            return alert('Error', 'Not a valid email.');

        setLoading(true);
        const a = await auth.fetchSignInMethodsForEmail(email);
        if (a.length > 0) {
            setLoading(false);
            alert('Error', 'Email already in use. Please Login to continue.');
        } else {
            setLoading(false);
            setAdmin(null)
            history.push({
                pathname: "/otp",
                state: { email, password },
            });
        }

    }
    useEffect(() => {
        if (password.length >= 8 && email != '' && toggleCheckBox) {
            setIsOk(true);
        } else {
            setIsOk(false);
        }

        if (password.length >= 12) {
            setPwdStrength({ status: 'strong', color: 'green' });
        } else {
            setPwdStrength({ status: 'weak', color: '#FF5252' });
        }
    }, [password, email, toggleCheckBox]);

    // for validating email
    useEffect(() => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(email).toLowerCase())) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }, [email]);
    return (
        <div className='login' >

            <AuthHeader label='Sign Up with Email' />

            <div className='sub'>
                <div className='auth_input_wrpr'>
                    <input
                        type="text"
                        className='auth_input'
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email'

                    />
                </div>
                <p style={{ fontSize: 12, alignSelf: 'flex-start' }}>You will need to verify that you own this account.</p>
                <div className='auth_input_wrpr'>
                    <input
                        type="password"
                        className='auth_input'
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Password'
                    />
                </div>
                <p style={{ fontSize: 12, alignSelf: 'flex-start' }}>Please enter a password with at least 8 characters.</p>

                <div className='cbx_wrpr' >
                    <input type='checkbox' onChange={e => setToggleCheckBox(e.target.checked)} />
                    <p style={{ fontSize: 12, alignSelf: 'flex-start', marginTop: 20 }}>I have read and agree to the Privacy Policy and Terms of Service.</p>
                </div>

                <NewBtn disabled={!isOk} onClick={signupHandler} label={'Log In'} containerStyle={{ marginTop: 50, width: '100%' }} />
            </div>

        </div>
    )
}

export default SignUp