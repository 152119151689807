import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { auth } from '../../firebase'
import './setupProfile.css'
import firebase from "../../firebase";
import Navigation from '../../components/Header/Navigation';
import PhotoWrapper from './components/PhotoWrapper';
import UserNameWrpr from './components/UserNameWrpr';
import SocialBtnComponent from '../../components/socialBtnComponent/SocialBtnComponent';
import Modal from 'react-modal';
import EditSocialMediaModal from '../account/components/EditSocialMediaModal';
import NewBtn from '../../components/btn/NewBtn';
import { AppContext } from '../../contexts/AppContext';


const SetupProfile = () => {

    const history = useHistory()

    const userAuth = auth.currentUser

    const { admin, staff, setStaff } = useContext(AppContext)

    const { staffs, allUpdate } = (useLocation()).state ?? {}


    const [showMediaModal, setShowMediaModal] = useState(false);
    const [loading, setLoading] = useState('');
    const [user, setUser] = useState('')


    const socialMediaComps = [
        { title: 'Instagram', key: 'one' },
        { title: 'Facebook', key: 'two' },
        { title: 'Whatsapp', key: 'three' },
        { title: 'Youtube', key: 'four' },
        { title: 'SnapChat', key: 'five' },
        { title: 'Twitter', key: 'six' },
        { title: 'Linkedin', key: 'seven' },
        { title: 'Pinterest', key: 'eight' },
        { title: 'Spotify', key: 'fifteen' },
        { title: 'Moj', key: 'Sixteen' },
        { title: 'Quora', key: 'Seventeen' },
        { title: 'MX TakaTak', key: 'Eighteen' },
        { title: 'Roposo', key: 'Nineteen' },
        { title: 'Josh', key: 'Twenty' },
        { title: 'ClubHouse', key: 'Twentyone' },
        { title: 'Discord', key: 'Twentytwo' },
        { title: 'Telegram', key: 'Twentythree' },
    ];
    const contactDetails = [
        { title: 'Call', key: 'nine' },
        { title: 'Contact', key: 'ten' },
        { title: 'Text', key: 'eleven' },
        { title: 'Email', key: 'twelve' },
        { title: 'Google Maps', key: 'thirteen' },
        { title: 'Custom Url', key: 'fourteen' },
    ];

    const getAccountDetails = async () => {
        setLoading(true)
        try {
            firebase
                .firestore()
                .collection("Users")
                .where("email", "==", admin ? staff?.email : userAuth.email)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        console.log(doc.data())

                        if (!doc?.data()?.username) {
                            history.push('/create_username')
                        } else {
                            setUser({ id: doc.id, ...doc.data() })

                        }


                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })
                .then(() => {
                    // getProfPic(username, tempExtension);
                });
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    const getAllUpdateDetails = async () => {
        setLoading(true)
        try {

            await firebase.firestore().collection("CommonDatas")
                .doc('data')
                .get().then((doc) => {
                    if (doc.data()) {
                        // socialMedia = doc.data().socialMedia
                        setUser({ id: doc.id, ...doc.data() })
                    }
                })
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }


    const updateAllHandler = async () => {
        let promises = staffs.map(async (staff) => {

            let socialMedia = staff.socialMedia



            user.socialMedia.forEach((s, id) => {

                let alreadyPresent = socialMedia.some(a => s.title == a.title)

                if (alreadyPresent) {
                    user.socialMedia.forEach(media => {
                        if (s.title == media.title) {
                            socialMedia[id] = media
                        }
                    })
                } else {
                    socialMedia.push(s)
                }
              
            })
            // console.log(socialMedia, 'sooo')
            // return
            return await firebase.firestore().collection("Users")
                .doc(staff.id)
                .update({
                    socialMedia
                })

        }
        )

        await Promise.all(promises).then(() => {
            alert('Updated')
            history.goBack()
        })
    }

    const saveHandler = async () => {

        if (allUpdate) {

            return updateAllHandler()
        }

        setLoading(true)
        try {
            await firebase.firestore()
                .collection('Users')
                .doc(admin ? staff?.id : userAuth.emailuser.id)
                .update({ ...user })
            if (admin) {
                history.push('/staff_dashboard')
                setStaff(null)
            } else {
                history.push('/account_details')

            }
            setLoading(false)

        } catch (error) {
            alert('Update error')
            setLoading(false)

        }

    }



    useEffect(() => {

        if (!userAuth) {
            history.push('/user_login')
        } else {

            if (admin && !allUpdate && !staff) {
                history.push('/staff_dashboard')
            }

            if (!showMediaModal && !allUpdate) {
                getAccountDetails()

            }
            if (!showMediaModal && allUpdate) {
                getAllUpdateDetails()

            }
        }
    }, [userAuth, showMediaModal, admin])


    if (loading) return <h1>Loading...</h1>

    return (
        <div className='setup_profile' >

            <Navigation label={'Setup Profile'} />
            <div style={{ display: 'flex' }} >
                {!allUpdate &&
                    <div style={{ width: '40%', display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
                        <PhotoWrapper user={user} setUser={setUser} />

                        <UserNameWrpr user={user} setUser={setUser} />

                    </div>}
                <div style={{ width: allUpdate ? '100%' : '60%', ...(allUpdate && { display: 'flex' }) }}>
                    <div>
                        <h3 className='subTitle' >Social Media</h3>
                        <div className="socialContainer">
                            {
                                socialMediaComps?.map((item) => (
                                    <SocialBtnComponent setShowMediaModal={setShowMediaModal} from='ACCOUNT' socialMedia={user.socialMedia} item={item} publicUsername={user.publicUsername} />
                                ))
                            }


                        </div>
                    </div>
                    <div>
                        <h3 className='subTitle'>Contacts</h3>
                        <div className="socialContainer">
                            {
                                contactDetails?.map((item) => (
                                    <SocialBtnComponent setShowMediaModal={setShowMediaModal} from='ACCOUNT' item={item} socialMedia={user.socialMedia} publicUsername={user.publicUsername} />
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>

            <NewBtn label={allUpdate ? 'Save Profile - Update All Fields' : 'Save Profile'} containerStyle={{ margin: 'auto', marginBottom: 15 }} onClick={saveHandler} />

            <Modal
                isOpen={showMediaModal}
                // onAfterOpen={()}
                onRequestClose={() => setShowMediaModal(null)}
                style={{
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        // bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: "white",
                        height: '70vh',

                    },
                    overlay: {
                        position: "fixed",
                        display: "flex",
                        justifyContent: "center",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0,0,0, .8)",
                        zIndex: "1000",
                        overflowY: "auto"
                    }

                }}
                contentLabel="Example Modal"
            >
                <EditSocialMediaModal
                    user={user}
                    media={showMediaModal}
                    setShowMediaModal={setShowMediaModal}
                    allUpdate={allUpdate}
                    staffs={staffs}
                />
            </Modal>



        </div>
    )
}

export default SetupProfile