import React from "react";
import Intro from "./Intro";
import Connect from "./Connect";

const MainIntro = () => {
  return (
    <div
 
    >
      <Intro />
      <Connect />
    </div>
  );
};

export default MainIntro;
