import React from "react";
import Header from "./Header";
import Middle from "./Middle";
import "./Home.css";
const Home = ({ productsRef }) => {
  return (
    <div className="itemcontainer">
      <Header />
      <div className="">
        <Middle productsRef={productsRef} />
      </div>
    </div>
  );
};

export default Home;
