import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Btn from '../../../components/btn/Btn'
import Logo from '../../../assets/images/bump_logo.webp'
import { auth } from '../../../firebase'
import { AppContext } from '../../../contexts/AppContext'
const StaffHeader = ({ staffs }) => {


    const { admin } = useContext(AppContext)
    const history = useHistory()

    const logoutHandler = async () => {
        let confirm = window.confirm('Are you sure?')
        if (confirm) {
            auth.signOut()
            history.push('/staff_login')
        }
    }

    return (
        <div
            style={{
                padding: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <img src={admin?.logo ? admin?.logo : Logo} style={{ marginRight: 10, height: 150, width: 150, objectFit: 'contain', borderRadius: 10 }} />
            <h1>Staff Management</h1>
            <div style={{ flex: 1 }} />
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>

                <Btn
                    label={'Update All Staff'}
                    onClick={() => history.push('/setup_profile', { staffs, allUpdate: true })}
                    containerStyle={{ marginRight: 10, backgroundColor: 'green' }}
                />
                <Btn
                    label={'Add Staff'}
                    onClick={() => history.push('/add_staff')}
                />
                <Btn
                    label={'Logout'}
                    onClick={logoutHandler}
                    containerStyle={{ marginLeft:  10, backgroundColor: 'red' }}
                />
            </div>
        </div>
    )
}

export default StaffHeader