import React from 'react'

const NewBtn = ({ label, disabled, onClick, containerStyle }) => {
    return (
        <div
            onClick={onClick}
            style={{
                backgroundColor: disabled ? '#3838384D' : '#4784E1',
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 25,
                color: 'white',
                fontWeight: "700",
                cursor: 'pointer',
                ...containerStyle

            }}

        >
            <p>{label}</p>
        </div>
    )
}

export default NewBtn