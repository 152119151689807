import React from "react";

import "./intro.css";
import ReactPlayer from "react-player";

import Intro1Video from "../../assets/videos/intro1.mp4";
import Intro2Video from "../../assets/videos/intro2.mp4";
import AppleStoreLogo from "../../assets/images/web/appleStoreLogo.png";
import PlayStoreLogo from "../../assets/images/web/playStoreLogo.png";
const Intro = () => {
  return (
    <div className="intro">
      <div className="intro-sub1">
        <div>
          <h3 className="intro-t1"> GO TOUCHLESS</h3>
          <h1 className="intro-t2"> Introducing Bump</h1>
          <h3 className="intro-t3">
            {" "}
            BUMP is a NFC based digital business card which carries your
            information to be shared with just a tap!
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              color: "#434343",
            }}
          >
            Download Now
          </div>
          <div
            style={{
              paddingTop: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <a
              style={{
                textDecoration: "none",
              }}
              href={"https://apps.apple.com/us/app/bump-nfc/id1594000760"}
            >
              <img
                src={AppleStoreLogo}
                style={{ width: 40, cursor: "pointer" }}
              />
            </a>

            <a
              href={
                "https://play.google.com/store/apps/details?id=com.bump.nfc"
              }
              style={{
                textDecoration: "none",
              }}
            >
              <img
                src={PlayStoreLogo}
                style={{
                  paddingLeft: 20,
                  width: 40,
                  cursor: "pointer",
                }}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="intro-images">
        <div>
          <ReactPlayer
            url={[Intro2Video, Intro1Video]}
            width="100%"
            height="100%"
            loop
            muted
            playing
            playsinline
          />
          {/* <img
            className='intro-img1'
            src={home}
            alt="home"

          />

          <img
            className='intro-img2'
            src={qr}
            alt="qr"

          /> */}
        </div>
      </div>
    </div>
    // <div
    //   className="intro"

    // >
    //   <div
    //     className="info"

    //   >
    //     <h1
    //       className='intro-t1'
    //     >
    //       GO TOUCHLESS
    //     </h1>
    //     <h1
    //      className='intro-t2'

    //     >
    //       Introducing Bump
    //     </h1>
    //     <h1

    //       style={{
    //         fontFamily: "Poppins",
    //         fontStyle: "normal",
    //         fontWeight: "normal",
    //         fontSize: "22px",
    //         lineHeight: "180%",
    //         width: 500,
    //       }}
    //     >
    //       BUMP is a NFC based digital business card which carries your
    //       information to be shared with just a tap!
    //     </h1>
    //   </div>
    //   <div className="images">
    //     <img
    //       src={home}
    //       alt="home"
    //       style={{
    //         marginLeft: 200,
    //         position: "absolute",
    //       }}
    //     />

    //     <img
    //       src={qr}
    //       alt="qr"
    //       style={{
    //         marginLeft: 320,
    //         marginTop: -40,
    //       }}
    //     />
    //   </div>
    // </div>
  );
};

export default Intro;
