import React, { useEffect, useState, useLayoutEffect } from "react";
import VCard from "vcard-creator";
import FileSaver from "file-saver";
import "./socialBtnStyle.css";

import PhonePe from '../../assets/images/PhonePe.png'
import GPay from '../../assets/images/gpay.png'
import Paytm from '../../assets/images/Paytm.png'
import BharatPay from '../../assets/images/bharatPay.png'
import AmazonPay from '../../assets/images/amznPay.png'
import Instagram from "../../assets/images/instagram.png";
import Whatsapp from "../../assets/images/whatsapp.png";
import Facebook from "../../assets/images/facebook.png";
import youtube from "../../assets/images/youtube.png";
import snapchat from "../../assets/images/snapchat.png";
import twitter from "../../assets/images/twitter.png";
import linkedIN from "../../assets/images/linkedIN.png";
import clubhouse from "../../assets/images/clubHouse.png";
import call from "../../assets/images/call.png";
import contact from "../../assets/images/contacts.png";
import textMessage from "../../assets/images/textMessage.png";
import emailIcon from "../../assets/images/email.png";
import location from "../../assets/images/location.png";
import customURL from "../../assets/images/URL.png";
import Roposo from "../../assets/images/Roposo.png";
import Takatak from "../../assets/images/MXTakatak.png";
import Moj from "../../assets/images/moj.png";
import Quora from "../../assets/images/quora.png";
import Spotify from "../../assets/images/spotify.png";
import Josho from "../../assets/images/Josh.png";
import Pinterest from "../../assets/images/pinterest.png";
import Discord from "../../assets/images/discord.png";
import Telegram from "../../assets/images/telegram.png";
import { LinkHandler } from "../../utils/linkHandler";
import TickIco from '../../assets/images/tick.png'

function SocialBtnComponent({ item, publicUsername, from, socialMedia, setShowMediaModal }) {



  const [icon, setIcon] = useState();
  const [mediaData, setMediaData] = useState();


  const setSocialMedia = () => {
    let data = null

    socialMedia?.forEach((s) => {
      if (s.title == item.title) {

        data = s
      }
    })
    setMediaData(data)
    // console.log(data)
  }

  // This is to set icon accordingly.
  useEffect(() => {
    switch (item.title) {
      case "Instagram":
        setIcon(Instagram);
        break;
      case "Facebook":
        setIcon(Facebook);
        break;
      case "Whatsapp":
        setIcon(Whatsapp);
        break;
      case "Discord":
        setIcon(Discord);
        break;
      case "Telegram":
        setIcon(Telegram);
        break;
      case "Youtube":
        setIcon(youtube);
        break;
      case "SnapChat":
        setIcon(snapchat);
        break;
      case "Twitter":
        setIcon(twitter);
        break;
      case "Linkedin":
        setIcon(linkedIN);
        break;
      case "ClubHouse":
        setIcon(clubhouse);
        break;
      case "Call":
        setIcon(call);
        break;
      case "Contact":
        setIcon(contact);
        break;
      case "Text":
        setIcon(textMessage);
        break;
      case "Email":
        setIcon(emailIcon);
        break;
      case "Google Maps":
        setIcon(location);
        break;
      case "Custom Url":
        setIcon(customURL);
        break;
      case "Pinterest":
        setIcon(Pinterest);
        break;
      case "Spotify":
        setIcon(Spotify);
        break;
      case "Moj":
        setIcon(Moj);
        break;
      case "Quora":
        setIcon(Quora);
        break;
      case "MX TakaTak":
        setIcon(Takatak);
        break;
      case "Roposo":
        setIcon(Roposo);
        break;
      case "Josh":
        setIcon(Josho);
        break;
      case 'PhonePe':
        setIcon(PhonePe);
        break;
      case 'Paytm':
        setIcon(Paytm);
        break;
      case 'GPay':
        setIcon(GPay);
        break;
      case 'BharatPay':
        setIcon(BharatPay);
        break;
      case 'AmazonPay':
        setIcon(AmazonPay);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setSocialMedia()
  }, [socialMedia])

  if (from == 'ACCOUNT')
    return (
      <div
        className="contactBtn account_socio_btn"
        style={{
          margin: 5,
          padding: 5,
          position: 'relative'
        }}
        onClick={() => setShowMediaModal({ ...item, ...mediaData })}
      >
        {mediaData && <img src={TickIco} style={{ width: 25, height: 25, alignSelf: 'flex-end', position: 'absolute', top: 0, right: -5 }} />}
        <img src={icon} alt="" srcset="" />
        <p
          style={{
            color: "black",
          }}
        >
          {item.title}
        </p>
      </div>
    )


  return (
    <div
      className="contactBtn"
      onClick={() => LinkHandler(item, publicUsername)}
    >
      <img src={icon} alt="" srcset="" />
      <p
        style={{
          color: "white",
        }}
      >
        {item.title}
      </p>
    </div>
  );
}

export default SocialBtnComponent;
