import React, { useState } from 'react'
import { useEffect } from 'react'
import RightArrow from '../../../assets/images/right_arrow.png'
import firebase from '../../../firebase'
import DeleteIco from '../../../assets/images/delete.png'

const StaffItem = ({id, staff, onStaffClick, codes, getAllStaffs, getAllcodes }) => {

    // const [id, setId] = useState('')
    // const [code, setCode] = useState('')
    // const [codeFound, setCodeFound] = useState(false)
    const [codeDoc, setCodeDoc] = useState({})
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)


    const addEntry = async () => {

        setLoading(true)
        try {
            await firebase.firestore().collection('dataEntry')
                .doc()
                .set({
                    id: codeDoc.id,
                    name: staff.username,
                    code: codeDoc.code
                }).then(async () => {
                    await getAllcodes()
                    setEditing(false)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })
        } catch (error) {
            console.log(error)
        }

    }
    const editEntry = () => {
        setLoading(true)
        try {
            firebase.firestore().collection('dataEntry')
                .doc(codeDoc.docId)
                .update({
                    id: codeDoc.id,
                    name: staff.username,
                    code: codeDoc.code
                }).then(async () => {
                    await getAllcodes()
                    setEditing(false)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })

        } catch (error) {
            console.log(error)
        }
    }


    const validationHandler = async () => {
        if (!codeDoc.code || !codeDoc.id) return alert('Id and code are mandatory')
        let idExist = false

        let promise2 = await firebase.firestore().collection("dataEntry").where('id', '==', codeDoc.id)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    idExist = true
                    querySnapshot.forEach((doc) => {
                        if (doc.id == codeDoc.docId) idExist = false
                    })
                }
            })

        await Promise.all([promise2,]).then(() => {
            if (idExist) {
                return window.alert('Provide unique Id')
            }
            if (codeDoc.docId) {
                editEntry()

            } else {
                addEntry()

            }
        })

    }

    const deleteStaff = async () => {

        let confirm = window.confirm('Are you sure want to delete ? ')
        if (!confirm) return

        try {
            await firebase
                .firestore()
                .collection("Users")
                .doc(staff.id)
                .delete()
                .then((querySnapshot) => {

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })

            getAllStaffs()
        } catch (error) {
            console.log(error);
            alert('Error, Please try again')
        }
    }

    useEffect(() => {
        codes.forEach(c => {
            if (c.name == staff.username) {
                setCodeDoc(c)
            }
        })
    }, [codes])

    return (
        <tr
            key={staff.id}
            style={{ marginBottom: 15 ,backgroundColor:id%2==0?'#CBE5F3':'white' }}
        >
            <td style={{ textAlign: 'center',borderRight: `1px solid gray` }}>{staff.username}</td>
            <td style={{ textAlign: 'center' ,borderRight: `1px solid gray`}}>{staff.email}</td>
            {editing ? <>
                <td style={{ textAlign: 'center',borderRight: `1px solid gray` }}>
                    <input
                        // style={{ marginBottom: 10 }}
                        className='input'
                        placeholder='Id'
                        onChange={(e) => { codeDoc.id = e.target.value; setCodeDoc({ ...codeDoc }) }}
                        value={codeDoc.id}
                    />
                </td>
                <td style={{ textAlign: 'center',borderRight: `1px solid gray` }}>
                    <input
                        // style={{ marginBottom: 10 }}
                        className='input'
                        placeholder='Code'
                        onChange={(e) => { codeDoc.code = e.target.value; setCodeDoc({ ...codeDoc }) }}
                        value={codeDoc.code}
                    />
                </td>
            </> :
                <>
                    <td style={{ textAlign: 'center',borderRight: `1px solid gray` }}>
                        {codeDoc.id ?? '-'}
                    </td>
                    <td style={{ textAlign: 'center',borderRight: `1px solid gray` }}>
                        {codeDoc.code ?? '-'}
                    </td>
                </>}
            <td
                style={{ textAlign: 'center', color: 'blue',borderRight: `1px solid gray`, textDecorationLine: 'underline', fontSize: 11, cursor: 'pointer' }}
                onClick={() => editing ? validationHandler() : setEditing(!editing)}
            >
                {editing ? 'Save' : 'Edit'}
            </td>
            <td style={{ textAlign: 'center' ,borderRight: `1px solid gray`}}>
                <img

                    onClick={() => onStaffClick(staff)}
                    src={RightArrow}
                    style={{ width: 25, height: 25, cursor: 'pointer' }}
                />
            </td>

            <td style={{ textAlign: 'center' ,}}>
                <img

                    onClick={deleteStaff}
                    src={DeleteIco}
                    style={{ width: 25, height: 25, cursor: 'pointer' }}
                />
            </td>

        </tr>
    )
}

export default StaffItem