import React, { useEffect } from 'react'
import './howTo.css'
import Image1 from './images/howto_1.jpg'
import Image2 from './images/howt0_2.png'
import Image3 from './images/howto_3.png'
import Image4 from './images/howto_4.png'
import Image5 from './images/howto_5.png'
import Image6 from './images/howto_6.png'
import Header from '../../components/Home/Header'
import Bump from '../../components/Bump/Bump'
import { useLocation } from 'react-router-dom'

function HowTo() {
    const location = useLocation();
    useEffect(() => {
        if (location.pathname == '/a1005625') {
            window.open('https://shop.bumpme.in/pages/how-to-use-bump', '_self')
        }
    }, [])

    const Item = ({ step, image, description }) => {
        return (
            <div className='item' >

                <h1>{step}</h1>

                <div className='sec'
                >
                    <div className='img_wrpr' >
                        <img src={image} className='img' />
                    </div>
                    <div className='text_wrpr' >
                        <p className='txt' >{description}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='how_to' >
            <Header />
            <div >
                <Item
                    step={'Step 1'}
                    image={Image1}
                    description={"Click on Sign up if you’re a new user, or click on Log In if you’re an existing user."}
                />
            </div>
            <Item
                step={'Step 2'}
                image={Image2}
                description={"Fill in your details and click on Sign Up."}
            />
            <Item
                step={'Step 3'}
                image={Image3}
                description={"Profile photo, Public Name, Designation, and all details can be edited from Setup Profile page"}
            />
            <Item
                step={'Step 4'}
                image={Image4}
                description={"Add your social and contact details and click on save profile."}
            />


            <Item
                step={'Step 5'}
                image={Image5}
                description={"Once profile setting up is done, please hold the bump device to your phone for the app to program."}
            />

            <Item
                step={'Step 6'}
                image={Image6}
                description={"Your Bump is all setup now.\n Now you can\n BUMP.SHARE.CONNECT"}
            />

            <Bump />

        </div>
    )
}

export default HowTo
