import React from 'react'
import AuthHeader from './components/AuthHeader'
import './auth.css'
const AuthHome = () => {
    return (
        <div className='auth_home' >
            <AuthHeader label='login' />
        </div>
    )
}

export default AuthHome