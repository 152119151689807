import React, { useState } from 'react'
import './signUpStyle.css'

import firebase, { useAuth } from '../../firebase'
import { Link, useHistory } from 'react-router-dom'


const SignUpPage = () => {

    const history = useHistory()
    const { setUser, googleSignIn } = useAuth()


    const [email, setEmail] = useState('')
    const [password, setPassword,] = useState('')
    const [warning, setWarning] = useState()


    const signUpHandler = async () => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                setUser(userCredential.user)
                history.push('/')


            }).catch((error) => {
                console.log(error.message)
            })

    }
    const socialSignInHandler = async () => {
        await googleSignIn()
        history.push('/')
    }

    return (

        <div className='login-container' >
            <div className="login-content">
                <div className="div">
                    Lorem ipsum
                </div>
            </div>
            <div className="login-input">
                <h1 className="title">SignUP</h1>

                <button onClick={() => socialSignInHandler()} >GOOGLE</button>

                <div className="flash">
                    {warning && <p>{warning}</p>}
                </div>
                <div className="input-wrapper">
                    <div>
                        <label htmlFor="">Email:</label>
                        <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div>
                        <label htmlFor="">Password:</label>
                        <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button onClick={() => signUpHandler()} >SignUP</button>

                </div>
                <Link to='/login' >Login</Link>
            </div>

        </div>
    )
}

export default SignUpPage