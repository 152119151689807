import React from 'react'
import Navigation from '../../components/Header/Navigation'
import SettingsItem from './components/SettingsItem'
import { auth } from '../../firebase'
import './settings.css'
import { useHistory } from 'react-router-dom'

const Settings = () => {

    const history = useHistory()
    const logoutHandler = async () => {
        let confirm = window.confirm('Are you sure want to logout ?')
        if (confirm) {
            auth.signOut()

        }
    }

    return (
        <div className='acc_settings' >

            <Navigation label={'Settings'} />

            <div className='sub'>
                <SettingsItem label='Setup Profile' onClick={() => history.push('/setup_profile')} />
                {/* <SettingsItem label='About' path={'/setup_profile'} />
                <SettingsItem label='Help' path={'/setup_profile'} /> */}
                <SettingsItem label='Delete account' onClick={() => history.push('/delete_account')} />
                <p
                    onClick={logoutHandler}
                    className='logout'
                >Logout</p>
            </div>
        </div>
    )
}

export default Settings