import React from "react";
import nfc from "./nfc.png";
import check from "./check.png";
import "./intro.css";
import ReactPlayer from "react-player";

import Intro1Video from "../../assets/videos/intro1.mp4";
import Intro2Video from "../../assets/videos/intro2.mp4";
const Connect = () => {
  return (
    <div className="connect">
      <div>
        <ReactPlayer
          url={[Intro1Video]}
          width="100%"
          height="100%"
          loop
          muted
          playing
          playsinline
        />
        {/* <img
            className='intro-img1'
            src={home}
            alt="home"

          />

          <img
            className='intro-img2'
            src={qr}
            alt="qr"

          /> */}
      </div>
      <div className="connect-info">
        <h1 className="connect-t1">
          Connecting with people has never been easier
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={check} alt="check" />
          <h1 className="connect-t2">
            A single product for all your business and social connections
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={check} alt="check" style={{}} />
          <h1 className="connect-t2">
            The fastest and most secure way to share any of your social links
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={check} alt="check" style={{}} />
          <h1 className="connect-t2">
            Get a BUMP, Configure it using our app and you’re all set.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Connect;
