import React, { useState } from 'react'
import './loginStyle.css'
import { Link, useHistory } from 'react-router-dom';
import firebase from '../../firebase'

const LoginPage = () => {

    const history = useHistory()


    const [email, setEmail] = useState('')
    const [password, setPassword,] = useState('')
    const [warning, setWarning] = useState()


    const loginHandler = () => {

        if (!email) {
            return setWarning('Please provide Email')
        }
        if (!password) {
            return setWarning('Please provide password')
        }
        setWarning('')

        console.log(email, password)
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                // var user = userCredential.user;
                // ...
                history.push('/admin')
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
            });

    }

    return (

        <div className='login-container' >
            <div className="login-content">
                <div className="div">
                    Lorem ipsum
                </div>
            </div>
            <div className="login-input">
                <h1 className="title">Login</h1>
                <div className="flash">
                    {warning && <p>{warning}</p>}
                </div>
                <div className="input-wrapper">
                    <div>
                        <label htmlFor="">Email:</label>
                        <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div>
                        <label htmlFor="">Password:</label>
                        <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button onClick={() => loginHandler()} >Login</button>

                </div>
                <Link to='/signUp' >Signup</Link>
            </div>

        </div>
    )
}

export default LoginPage