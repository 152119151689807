import React, { useEffect, useState } from 'react'
import NewBtn from '../../components/btn/NewBtn';
import AuthHeader from '../auth/components/AuthHeader'
import '../createUserName/createUsername.css'
import firebase from "../../firebase";
import { auth } from '../../firebase'
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';


const AddStaff = () => {


    const history = useHistory()
    const { admin, staff, setStaff } = useContext(AppContext)

    console.log(admin)

    const [username, setUsername] = useState('');
    const [isAvailable, setIsAvailable] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [docId, setDocId] = useState('')
    const [userNameError, setUserNameError] = useState(false);
    const prohibitedUsernames = ['about', 'admin', 'products', 'privacy', 'termsandconditionsm', 'custom', 'terms']



    const CreateUsername = async () => {
        if (!email) return alert('Email id needed')

        // add username to userdata
        setLoading(true);
        await firebase.firestore()
            .collection('Users')
            .add({
                email,
                adminId: admin.id,
                username: username.toLowerCase(),
                socialMedia: [],
                profStatus: true
            })
            .then((doc) => {
                console.log('User added!');
                // navigation.navigate('SetupProfile');
                setStaff({
                    id: doc.id,
                    email,
                    adminId: admin.id,
                    username: username.toLowerCase(),
                    socialMedia: [],
                    profStatus: true
                })
                history.push('/setup_profile')
            });
    };

    const dataEntryCodeChecker = async () => {
        let isExist = true
        await firebase.firestore()
            .collection('dataEntry')
            // Filter results
            .where('code', '==', username.toLowerCase())
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.size > 0) {
                    isExist = true

                } else {

                    isExist = false;
                }
            })
        if (isExist) return true;
        return false
    }

    // to check whether username available or not
    const usernameChecker = async () => {
        if (username.includes(" ") || username.includes(".") || username.includes(",") || username.includes(";") || username.includes(":")) {

            return setUserNameError(true)
        }
        setUserNameError(false)
        setIsAvailable(false)
        await firebase.firestore()
            .collection('Users')
            // Filter results
            .where('username', '==', username.toLowerCase())
            .get()
            .then(async (querySnapshot) => {
                if (querySnapshot.size > 0) {
                    setIsAvailable(false);

                }
                else if (prohibitedUsernames.includes(username)) {
                    setIsAvailable(false)

                }
                else if (await dataEntryCodeChecker()) {
                    setIsAvailable(false);
                }
                else {
                    setIsAvailable(true);
                }
            });
    };

    // to retrieve user email.
    useEffect(() => {
        const { email } = auth.currentUser;

        firebase.firestore()
            .collection('Users')
            // Filter results
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    setDocId(doc.id);


                });

            });

    }, []);

    // to check whether the entered username exits.
    useEffect(() => {
        if (username) {
            usernameChecker();
        }
    }, [username]);

    const onClickHandler = async () => {
        usernameChecker().then(async () => {
            if (userNameError) {
                return
            }
            if (isAvailable) {

                let confirm = window.confirm('Your username and URL cannot be changed later. Are you sure you want to proceed',)

                if (confirm) {
                    CreateUsername()
                }


            } else {
                console.log('username not available');
            }
        });
    };



    return (
        <div className='create_username'>
            <AuthHeader label={'Add Staff'} />

            <div className='sub' >

                <p className='info' >Please choose a unique username for yourself.</p>

                <div className='auth_input_wrpr'>
                    <input
                        type="text"
                        className='auth_input'
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email'
                        value={email}
                    />
                </div>
                <div className='auth_input_wrpr'>
                    <input
                        type="text"
                        className='auth_input'
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder='User Name'
                        value={username}
                    />
                </div>
                {console.log(isAvailable, !userNameError)}
                <div className='link_wrpr' >
                    <p style={{ fontSize: 15, alignSelf: 'flex-start', color: '#38383850' }}>www.bump.co/<span style={{ backgroundColor: "#C4C4C450", padding: 3, borderRadius: 5, color: 'black' }} >{username}</span></p>
                    <div className='dot' style={{ backgroundColor: isAvailable ? '#C5FDAA' : '#FF5252' }} >
                        <p >  {(isAvailable || userNameError) ? '✔' : '!'}</p>
                    </div>
                </div>


                <NewBtn onClick={onClickHandler} label={'Create Username'} containerStyle={{ marginTop: 50 }} />

            </div>
        </div>
    )
}

export default AddStaff